import styled from "styled-components/macro"

import { Pagination } from "@mui/material"

import { spacing } from "src/ui/spacing"

export function MPagination({
  limit,
  offset,
  setOffset,
  totalCount,
}: {
  limit: number
  offset: number
  setOffset: (offset: number) => void
  totalCount: number
}) {
  const nbrPages = totalCount ? Math.ceil(totalCount / limit) : undefined

  const hasMultiplePages = !!totalCount && limit < totalCount

  function handleChange(event: object, pageNumber: number) {
    setOffset((pageNumber - 1) * limit)
  }

  if (!hasMultiplePages) {
    return null
  }

  return (
    <StyledBox>
      <Pagination
        count={nbrPages}
        defaultPage={Math.round(offset / limit) + 1}
        onChange={handleChange}
        color="primary"
      />
    </StyledBox>
  )
}

const StyledBox = styled.div`
  margin: ${spacing.S};
`
