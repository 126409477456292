import styled from "styled-components/macro"

import { LoansList } from "src/components/Loans/LoansList"
import { PersonalLoans } from "src/components/Loans/PersonalLoans"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { spacing } from "src/ui/spacing"

export function Loans() {
  return (
    <div>
      <PageTitle pageLabel="Loans"></PageTitle>

      <Box>
        <LoansList />

        <PersonalLoans />
      </Box>
    </div>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: repeat(auto-fit, minmax(720px, 1fr));
`
