import { TenantRoutes } from "src/router/TenantRoutes"

const UsersRoute = "/users"
const CreateUsersRoute = `${UsersRoute}/create`

const Basic = {
  login: "/login",
  forgotPassword: "/forgot-password",
  dashboard: "/dashboard",
  admin: "/admin",
}

const ProfileRoutes = {
  userProfile: "/user-profile",
  get resetPassword() {
    return `${this.userProfile}/reset-password`
  },
}

const LoansRoutes = {
  loans: "/loans",
  get loanRequest() {
    return `${this.loans}/loan-request`
  },
}

const GroupInterestRoutes = {
  groupInterest: "/group-interest",
  get addGroupInterest() {
    return `${this.groupInterest}/add-group-interest`
  },
}

const InvestmentRoutes = {
  investments: "/investments",
  get addinvestment() {
    return `${this.investments}/add-investment`
  },
}

const UpdateUsersRoute = {
  path: (userId: string) => `${UsersRoute}/${userId}/update`,
  get paramPath() {
    return this.path(":userId")
  },
} as const

const Login = {
  path: () => `${Basic.login}`,
  get paramPath() {
    return this.path()
  },
} as const

export const AppRoutes = {
  Basic,
  UsersRoute,
  CreateUsersRoute,
  UpdateUsersRoute,
  ProfileRoutes,
  LoansRoutes,
  GroupInterestRoutes,
  InvestmentRoutes,
  Login,
  ...TenantRoutes,
} as const
