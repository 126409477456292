import { getToken, onMessage } from "@firebase/messaging"
import { UseMutationResult } from "@tanstack/react-query"

import { messaging } from "src/firebase/firebase"

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY

async function requestNotificationsPermissions(
  postregistrationToken: UseMutationResult<
    any,
    unknown,
    {
      registrationToken: string
    },
    unknown
  >
) {
  console.log("Requesting notifications permissions...")
  const permission = await Notification.requestPermission()

  if (permission === "granted") {
    await saveMessagingDeviceToken(postregistrationToken)
  } else {
    console.log("Unable to get permission to notify.")
  }
}

export async function saveMessagingDeviceToken(
  postregistrationToken: UseMutationResult<
    any,
    unknown,
    {
      registrationToken: string
    },
    unknown
  >
) {
  try {
    const fcmToken = await getToken(messaging, { vapidKey })

    if (!!fcmToken) {
      console.log("Got FCM device token: ", fcmToken)
      // Save token to user object

      postregistrationToken.mutate({ registrationToken: fcmToken })
      onMessage(messaging, (payload) => {
        if (!payload.notification?.title) {
          return console.log("No message title found")
        }
        console.log(
          "New foreground messaging from Firebase Messaging!",
          payload.notification
        )
        new Notification(payload.notification?.title, {
          body: payload.notification?.body,
          icon: "/logo192.png",
        })
      })
    } else {
      // Need to request permissions to show notifications
      //await requestNotificationsPermissions()
      await requestNotificationsPermissions(postregistrationToken)
    }
  } catch (error) {
    console.log("An error occured while getting FCM token", error)
  }
}
