import { useState } from "react"
import styled from "styled-components/macro"

import { MAvatar } from "src/components/Avatar/MAvatar"
import { ChangePassword } from "src/components/UserProfile/ChangePassword"
import { MembershipList } from "src/components/UserProfile/MembershipList"
import { useAuth } from "src/contexts/AuthProvider"
import { useFetchInvestmentSummary } from "src/data/investments/investmentQueries"
import { useFetchLoanSummary } from "src/data/loans/loanQueries"
import { MButton } from "src/ui/Button/MButton"
import { paletteColor } from "src/ui/colors"
import { ReactComponent as AccountCircleIcon } from "src/ui/icons/AccountCircle.svg"
import { ReactComponent as LockIcon } from "src/ui/icons/Lock.svg"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { spacing } from "src/ui/spacing"
import {
  Caption,
  Caption2,
  Subtitle1,
  TypographyBody2,
} from "src/ui/typography"

export const enum ProfileSelection {
  MEMBERSHIP = "membership",
  CHANGE_PASSWORD = "changePassword",
}

export function UserProfile() {
  const { auth } = useAuth()
  const [selectedItem, setSelectedItem] = useState<ProfileSelection>(
    ProfileSelection.MEMBERSHIP
  )

  const fetchInvestmentSummary = useFetchInvestmentSummary({
    userId: auth?.user?.userId ?? "",
    reviewStatus: "Approved",
    options: { enabled: !!auth?.user?.userId },
  })

  const totalInvestment = fetchInvestmentSummary.data?.totalInvestment ?? 0

  const fetchLoanSummary = useFetchLoanSummary({
    userId: auth?.user?.userId ?? "",
    reviewStatus: "Approved",
    loanStatus: "Active",
    options: { enabled: !!auth?.user?.userId },
  })

  const loanPrinciple = fetchLoanSummary.data?.totalPrinciple ?? 0

  const networth = totalInvestment - loanPrinciple

  const showMembershipList = selectedItem === ProfileSelection.MEMBERSHIP
  const showChangePassword = selectedItem === ProfileSelection.CHANGE_PASSWORD

  if (fetchInvestmentSummary.isLoading || fetchLoanSummary.isLoading) {
    return <LoadingScreen />
  }

  return (
    <Box>
      <CardBox>
        <InfoBox>
          <AvatarBox>
            <MAvatar
              name={auth?.user?.name || ""}
              textSize="large"
              avatarSize={80}
            />
          </AvatarBox>

          <div>
            <Subtitle1>{auth?.user?.name}</Subtitle1>
            <Caption2>{auth?.user?.email}</Caption2>
          </div>

          <Subtitle1>Financial Status</Subtitle1>
          <StatusBox>
            <div>
              <Caption>NetWorth:</Caption>
              {networth.toLocaleString()}
            </div>
            <div>
              <Caption>Investments:</Caption>
              {totalInvestment.toLocaleString()}
            </div>
            <div>
              <Caption> Loans:</Caption>
              {loanPrinciple.toLocaleString()}
            </div>
          </StatusBox>
        </InfoBox>

        <div>
          <MButton
            variant="text"
            onClick={() => {
              setSelectedItem(ProfileSelection.MEMBERSHIP)
            }}
            startIcon={<AccountCircleIcon />}
            fullWidth
          >
            Adminstration fee
          </MButton>

          <MButton
            variant="text"
            onClick={() => {
              setSelectedItem(ProfileSelection.CHANGE_PASSWORD)
            }}
            startIcon={<LockIcon />}
            fullWidth
          >
            Change password
          </MButton>
        </div>
      </CardBox>

      <div>
        {showMembershipList && <MembershipList />}
        {showChangePassword && (
          <ChangePassword setSelectedItem={setSelectedItem} />
        )}
      </div>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.L};
`

const AvatarBox = styled.div`
  display: grid;
  justify-content: center;
  gap: ${spacing.S};
`

const InfoBox = styled.div`
  display: grid;
  gap: ${spacing.L};
  min-width: 300px;
`

const StatusBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacing.L};
  ${TypographyBody2};
`

const CardBox = styled.div`
  display: grid;
  padding: ${spacing.M};
  gap: ${spacing.L};
  border: 1px solid ${paletteColor.divider};
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
`
