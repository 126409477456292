import { ReactNode } from "react"
import styled from "styled-components/macro"

import { spacing } from "src/ui/spacing"
import { TypographyHeading4 } from "src/ui/typography"

export function PageTitle({
  pageLabel,
  children,
}: {
  pageLabel: string
  children?: ReactNode
}) {
  return (
    <>
      <StyledPageTitle>
        <div>{pageLabel}</div>
        {children}
      </StyledPageTitle>
    </>
  )
}

const StyledPageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.L};
  flex-wrap: wrap;
  margin-bottom: ${spacing.XL};
  ${TypographyHeading4};
`
