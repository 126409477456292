import styled from "styled-components/macro"

import { formatDistance } from "date-fns"

import { MTable } from "src/components/Table/MTable"
import { TReviewStatus } from "src/data/constants/globalTypes"
import { useFetchNotifications } from "src/data/notifications/notificationQueries"
import { INotification } from "src/data/notifications/notificationTypes"
import { paletteColor } from "src/ui/colors"
import { ReactComponent as ClearIcon } from "src/ui/icons/Clear.svg"
import { ReactComponent as DoneIcon } from "src/ui/icons/Done.svg"
import { ReactComponent as ScheduleIcon } from "src/ui/icons/Schedule.svg"
import { spacing } from "src/ui/spacing"
import { Caption2, Subtitle2, TypographySubtitle2 } from "src/ui/typography"

export function TransactionsList() {
  const fetchAllNotifications = useFetchNotifications({
    limit: 7,
    restrictToUser: false,
  })
  const allNotifications = fetchAllNotifications.data?.notifications || []

  const rows = allNotifications.map((notification) => {
    const notificationStatus = getNotificationReviewStatus(notification)

    return (
      <RowBox key={notification.notificationId}>
        <StatusIcon reviewStatus={notificationStatus} />
        <InfoBox>
          <MessageBox>{notification.message}</MessageBox>
          <Caption2>
            Updated{" "}
            {formatDistance(new Date(notification.updatedAt), new Date(), {
              addSuffix: true,
            })}
          </Caption2>
        </InfoBox>
        <Subtitle2>UGX{notification.value.toLocaleString()}</Subtitle2>
      </RowBox>
    )
  })

  return (
    <Box>
      <MTable title="Transactions" rows={rows} />
    </Box>
  )
}

function StatusIcon({ reviewStatus }: { reviewStatus: TReviewStatus }) {
  if (reviewStatus === "Approved") {
    return (
      <IconBox bgColor={paletteColor.success.lighter}>
        <DoneIcon fill={paletteColor.success.main} />
      </IconBox>
    )
  }

  if (reviewStatus === "Pending") {
    return (
      <IconBox bgColor={paletteColor.warning.lighter}>
        <ScheduleIcon fill={paletteColor.warning.main} />
      </IconBox>
    )
  }

  return (
    <IconBox bgColor={paletteColor.error.lighter}>
      <ClearIcon fill={paletteColor.error.main} />
    </IconBox>
  )
}

function getNotificationReviewStatus(
  notification: INotification
): TReviewStatus {
  const isRejected =
    notification.reviewResponse.some((r) => r.reviewStatus === "Rejected") &&
    notification.isOpen === false

  const isApproved =
    notification.reviewResponse.every((r) => r.reviewStatus === "Approved") &&
    notification.isOpen === false

  if (isRejected) return "Rejected"
  if (isApproved) return "Approved"
  return "Pending"
}

const Box = styled.div``

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: ${spacing.L};
  padding: ${spacing.M} ${spacing.L};
`

const IconBox = styled.div<{ bgColor: string }>`
  display: flex;
  border-radius: 50%;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  padding: ${spacing.XS};
`

const MessageBox = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${TypographySubtitle2};
`

const InfoBox = styled.div`
  display: grid;
  justify-content: flex-start;
  width: 100%;
  ${TypographySubtitle2};
`
