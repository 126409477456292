import { useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import {
  useFetchInterestDue,
  usePostInstalment,
} from "src/data/loans/loanQueries"
import { ILoan } from "src/data/loans/loanTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"
import { Heading5, Subtitle1 } from "src/ui/typography"

export function ServiceLoan({
  loan,
  setLoan,
}: {
  loan: ILoan
  setLoan: (loan: ILoan | null) => void
}) {
  const { auth } = useAuth()
  const [amount, setAmount] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  })

  const [error, setError] = useState(false)

  const fetchInterestDue = useFetchInterestDue({
    loanId: loan.loanId,
    options: { enabled: !!loan.loanId },
  })

  const interestDue = fetchInterestDue.data?.interest ?? 0

  const postInstalment = usePostInstalment()
  const amountRemaining = loan.amount - loan.amortisation

  function handlePostInstalment() {
    !!auth?.user &&
      amount.floatValue &&
      postInstalment.mutate(
        {
          loanId: loan.loanId,
          amortisation: amount.floatValue,
          interest: interestDue,
        },
        {
          onSuccess: () => {
            setLoan(null)
          },
        }
      )
  }

  return (
    <MDialog
      title="Service loan"
      open
      onClose={() => {
        setError(false)
        setLoan(null)
      }}
      onConfirm={handlePostInstalment}
      confirmLabel="Make payment"
      confirmButtonProps={{
        disabled: (amount.floatValue ?? 0) > amountRemaining,
      }}
    >
      <Box>
        <NumericFormat
          label="Interest to pay (UGX)"
          value={interestDue.toLocaleString()}
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          required
          disabled
        />

        <NumericFormat
          label="Instalment amount (UGX)"
          value={amount.formattedValue}
          defaultValue="0"
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setAmount(values)}
          required
        />

        <Subtitle1>
          Remaining principle: {amountRemaining.toLocaleString("en-GB")} UGX
        </Subtitle1>

        <Heading5>
          Total to pay:{" "}
          {Math.ceil(interestDue + (amount.floatValue ?? 0)).toLocaleString(
            "en-GB"
          )}{" "}
          UGX
        </Heading5>

        {error && <Alert severity="error">An error occured</Alert>}
        {(amount.floatValue ?? 0) > amountRemaining && (
          <Alert severity="error">
            You cannot pay more than your remaining principle
          </Alert>
        )}
      </Box>
    </MDialog>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL};
  align-items: center;
`
