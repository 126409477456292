import { ReactNode } from "react"
import styled from "styled-components/macro"

import { IFetchLoanSummary } from "src/data/loans/loanTypes"
import { paletteColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { Body, Caption2, Heading5 } from "src/ui/typography"

export function StatisticsCards({
  netTotal,
  loanTotals,
  investment,
  interestFromLoans,
  monthlyInterest,
}: {
  netTotal: number
  loanTotals: IFetchLoanSummary
  investment: number
  interestFromLoans: number
  monthlyInterest: number
}) {
  return (
    <Box>
      <StatisticsCard title="Networth (UGX)" body={netTotal.toLocaleString()} />
      <StatisticsCard
        title="Total investments (UGX)"
        body={investment.toLocaleString()}
      />
      <StatisticsCard
        title="Total interest (UGX)"
        body={monthlyInterest.toLocaleString()}
      />
      <StatisticsCard
        title="Interest from loans (UGX)"
        body={interestFromLoans.toLocaleString()}
      />
      <StatisticsCard
        title="Loans (UGX)"
        body={(loanTotals?.totalPrinciple ?? 0).toLocaleString()}
      />
    </Box>
  )
}

function StatisticsCard({
  title,
  body,
  description,
}: {
  title: ReactNode
  body: ReactNode
  description?: ReactNode
}) {
  return (
    <CardBox>
      <Body>{title}</Body>
      <Heading5>{body}</Heading5>
      {description && <Caption2>{description}</Caption2>}
    </CardBox>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${spacing.L};
  margin-bottom: ${spacing.XL};
`

const CardBox = styled.div`
  display: grid;
  gap: ${spacing.S};
  padding: ${spacing.XL};
  border-radius: 4px;
  border: 1px solid ${paletteColor.divider};
  background-color: #fff;
`
