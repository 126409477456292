import { useState } from "react"
import styled from "styled-components/macro"

import { TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { subMonths } from "date-fns"
import enGB from "date-fns/locale/en-GB"

import { MCard } from "src/components/Cards/MCard"
import {
  useFetchAnnualInterestPayout,
  usePostAnnualInterestPayout,
} from "src/data/annualInterestPayout/annualInterestPayoutQueries"
import { MButton } from "src/ui/Button/MButton"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"
import { Heading5 } from "src/ui/typography"

export function InterestPayout() {
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 12))
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [year, setYear] = useState<number>(new Date().getFullYear())

  const postAnnualInterestPayout = usePostAnnualInterestPayout()

  const fetchAnnualInterestPayout = useFetchAnnualInterestPayout({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    options: { enabled: !!startDate && !!endDate },
  })

  const annualInterestPayout =
    fetchAnnualInterestPayout.data?.totalInterestPayout ?? 0

  // const fetchCombinedInterest = useFetchCombinedInterestByDate({
  //   startDate: startDate.toISOString(),
  //   endDate: endDate.toISOString(),
  // })

  //const interestDetails = fetchCombinedInterest.data

  //const combinedInterest = interestDetails?.combinedInterestSum ?? 0

  const formattedAnnualInterestPayout = annualInterestPayout.toLocaleString()

  const bodyText = `Total interest of UGX ${formattedAnnualInterestPayout} 
  will be paid out equally to eligible members for the period ${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`

  function handlePostInterestPayment() {
    if (annualInterestPayout > 0) {
      return postAnnualInterestPayout.mutate(
        {
          amount: annualInterestPayout,
          year,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        {
          onSuccess: () => {
            setOpen(false)
          },
        }
      )
    }
  }

  const handleStartDateChange = (newValue: Date | null) => {
    if (!newValue) return

    setStartDate(newValue)
  }

  const handleEndDateChange = (newValue: Date | null) => {
    if (!newValue) return

    setEndDate(newValue)
  }

  return (
    <div>
      <StyledMCard
        title={
          <Title>
            <Heading5>Interest payout</Heading5>
          </Title>
        }
      >
        <Box>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enGB}
          >
            <DesktopDatePicker
              label="Start date"
              value={startDate}
              onChange={handleStartDateChange}
            />

            <DesktopDatePicker
              label="End date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>

          <TextField
            type="number"
            value={year}
            onChange={(e) => setYear(parseInt(e.target.value))}
          />
          <div>
            Total interest:{" UGX "}
            {formattedAnnualInterestPayout}
          </div>
          <MButton onClick={() => setOpen(true)}>Pay out interest</MButton>
        </Box>
      </StyledMCard>

      <MDialog
        open={open}
        onClose={() => {
          setOpen(false)
          postAnnualInterestPayout.reset()
        }}
        title="Pay out interest"
        confirmLabel="Pay interest"
        body={bodyText}
        confirmButtonProps={{
          disabled: annualInterestPayout < 1 || startDate >= endDate,
        }}
        loading={postAnnualInterestPayout.isPending}
        errorText={
          postAnnualInterestPayout.isError
            ? "There was a problem when paying out interest"
            : ""
        }
        onConfirm={handlePostInterestPayment}
      />
    </div>
  )
}

const StyledMCard = styled(MCard)`
  max-width: 55ch;
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.L};
`
