import styled from "styled-components/macro"

import { CircularProgress } from "@mui/material"

export function LoadingScreen() {
  return (
    <Box>
      <StyledCircularProgress />
    </Box>
  )
}

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  align-self: center;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  justify-content: center;
  min-height: 70vh;
  margin: auto;
`
