import { ReactNode } from "react"
import styled from "styled-components/macro"

import { colors, paletteColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import {
  Heading5,
  TypographyBody2,
  TypographySubtitle2,
} from "src/ui/typography"

export function MTable({
  header,
  rows,
  templateColumns = "1fr",
  title,
  action,
  pagination,
}: {
  rows: JSX.Element[]
  header?: JSX.Element[]
  templateColumns?: string
  title?: ReactNode
  action?: ReactNode
  pagination?: ReactNode
}) {
  return (
    <Box>
      <TitleBox>
        <Heading5>{title}</Heading5>
        {action}
      </TitleBox>

      <GridBox templateColumns={templateColumns}>
        {header?.map((columnHeader, index) => (
          <StyledHeader key={index}> {columnHeader}</StyledHeader>
        ))}

        {rows.map((row, index) => (
          <StyledRow key={index}>{row}</StyledRow>
        ))}
      </GridBox>
      {pagination && <div>{pagination}</div>}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  border-radius: 12px;
  background: #fff;
  border: 1px solid ${paletteColor.divider};
`

const TitleBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  justify-content: space-between;
  border-bottom: 1px solid ${paletteColor.divider};
  padding: ${spacing.M};
  align-items: center;
`

const GridBox = styled.div<{ templateColumns: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.templateColumns};
  width: 100%;
  box-sizing: border-box;
`

const StyledHeader = styled.header`
  border-bottom: 1px solid ${paletteColor.divider};
  padding: ${spacing.M} ${spacing.L};
  background-color: ${paletteColor.secondary.darker}10;
  ${TypographySubtitle2};
`

const StyledRow = styled.div`
  display: contents;
  ${TypographyBody2};

  :hover > * {
    background: ${colors.default}18;
  }

  > * {
    display: flex;
    border-bottom: 1px solid ${paletteColor.divider};
    padding: ${spacing.XS} ${spacing.L};
    flex-direction: column;
    justify-content: center;
  }
`
