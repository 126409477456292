import { Fragment, useState } from "react"
import styled from "styled-components/macro"

import {
  Badge,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import {
  useFetchNotifications,
  usePatchNotificationReview,
} from "src/data/notifications/notificationQueries"
import { IPostReviewResponse } from "src/data/notifications/notificationTypes"
import { MButton } from "src/ui/Button/MButton"
import { paletteColor } from "src/ui/colors"
import { ReactComponent as KeyboardArrowDownIcon } from "src/ui/icons/KeyboardArrowDown.svg"
import { ReactComponent as KeyboardArrowUpIcon } from "src/ui/icons/KeyboardArrowUp.svg"
import { ReactComponent as NotificationsOutlinedIcon } from "src/ui/icons/NotificationsOutlined.svg"
import { spacing } from "src/ui/spacing"
import { Body2, Caption1 } from "src/ui/typography"

export function NotificationCenter() {
  const { auth } = useAuth()
  const [open, setOpen] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState<
    string | null
  >(null)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const postApprovalByNotification = usePatchNotificationReview()

  const fetchOpenNotifications = useFetchNotifications({
    restrictToUser: true,
    limit: 5,
    isOpen: true,
    options: { enabled: !!auth?.user?.userId },
  })
  const userNotifications = fetchOpenNotifications.data?.notifications || []

  function handleApprovalByNotification(notification: IPostReviewResponse) {
    return postApprovalByNotification.mutate(notification)
  }

  return (
    <>
      <IconButton
        color="secondary"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setOpen(!open)
        }}
      >
        <Badge badgeContent={userNotifications.length} color="primary">
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <StyledPaper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  <List component="nav">
                    <ListItem key="notification1">
                      <NotificationsTitle>
                        <div>Notifications</div>
                        <Badge
                          badgeContent={userNotifications.length}
                          color="primary"
                        />
                      </NotificationsTitle>
                    </ListItem>

                    <Divider />

                    {fetchOpenNotifications.isLoading && (
                      <ListItem>
                        <CircularProgress />
                      </ListItem>
                    )}

                    {userNotifications.length <= 0 && (
                      <ListItem>No notifications ...</ListItem>
                    )}

                    {userNotifications.map((notification) => (
                      <Fragment key={notification.notificationId}>
                        <ListItemButton
                          alignItems="center"
                          onClick={() => {
                            setOpenNotification(!openNotification)
                            setSelectedNotification((prev) =>
                              prev === notification.notificationId
                                ? null
                                : notification.notificationId
                            )
                          }}
                        >
                          <ListItemText
                            primary={<Body2>{notification.service}</Body2>}
                            secondary={
                              <Caption1>{notification.message}</Caption1>
                            }
                          />

                          {selectedNotification ===
                          notification.notificationId ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </ListItemButton>

                        {selectedNotification ===
                          notification.notificationId && (
                          <ListItem>
                            <ButtonBox>
                              <MButton
                                size="small"
                                color="error"
                                onClick={() =>
                                  handleApprovalByNotification({
                                    userId: auth?.user?.userId || "",
                                    notificationId: notification.notificationId,
                                    reviewStatus: "Rejected",
                                  })
                                }
                                loading={postApprovalByNotification.isPending}
                                disabled={postApprovalByNotification.isPending}
                              >
                                Reject
                              </MButton>
                              <MButton
                                size="small"
                                color="success"
                                onClick={() =>
                                  handleApprovalByNotification({
                                    userId: auth?.user?.userId || "",
                                    notificationId: notification.notificationId,
                                    reviewStatus: "Approved",
                                  })
                                }
                                loading={postApprovalByNotification.isPending}
                                disabled={postApprovalByNotification.isPending}
                              >
                                Accept
                              </MButton>
                            </ButtonBox>
                          </ListItem>
                        )}

                        <Divider component="li" />
                      </Fragment>
                    ))}
                  </List>
                </div>
              </ClickAwayListener>
            </StyledPaper>
          </Fade>
        )}
      </StyledPopper>
    </>
  )
}

const NotificationsTitle = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

const StyledPaper = styled(Paper)`
  border: 1px solid ${paletteColor.secondary.light};
  min-width: 240px;
  max-width: 50ch;
`

const StyledPopper = styled(Popper)`
  z-index: 10;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacing.XL};
  width: 100%;
`
