import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { ProfileSelection } from "src/components/UserProfile/UserProfile"
import { useResetPassword } from "src/data/auth/authQueries"
import { MButton } from "src/ui/Button/MButton"
import { paletteColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { Heading5 } from "src/ui/typography"

export function ChangePassword({
  setSelectedItem,
}: {
  setSelectedItem: Dispatch<SetStateAction<ProfileSelection>>
}) {
  const resetPassword = useResetPassword()
  const [newPassword, setNewPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [error, setError] = useState(false)

  async function handleSubmit(event: ChangeEvent<{}>) {
    event.preventDefault()

    resetPassword.mutate(
      { oldPassword, newPassword },
      {
        onSuccess: () => {
          setError(false)
          setSelectedItem(ProfileSelection.MEMBERSHIP)
        },
        onError: () => setError(true),
      }
    )
  }

  return (
    <div>
      <Grid>
        <Heading5>Reset your password</Heading5>
        <FormBox onSubmit={handleSubmit}>
          <TextField
            size="small"
            label="Old password"
            value={oldPassword}
            type="password"
            onChange={(e) => setOldPassword(e.target.value)}
            required
            autoComplete="none"
          />
          <TextField
            size="small"
            label="New password"
            value={newPassword}
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            required
            autoComplete="none"
          />
          {error && (
            <Alert severity="error">
              There was a problem resetting your password
            </Alert>
          )}
          <MButton fullWidth type="submit">
            Reset password
          </MButton>
        </FormBox>
      </Grid>
    </div>
  )
}

const Grid = styled.div`
  display: grid;
  padding: ${spacing.L};
  gap: ${spacing.XL};
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${paletteColor.divider};
  max-width: 40ch;
`

const FormBox = styled.form`
  display: grid;
  justify-content: center;
  gap: ${spacing.M};
`
