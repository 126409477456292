import { createContext, ReactNode, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useQueryClient } from "@tanstack/react-query"

import { IUser } from "src/data/users/userTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"
import { AppRoutes } from "src/router/AppRoutes"

export interface IAuthUser {
  accessToken: string | null
  user: IUser | null
}

interface IAuth {
  auth: IAuthUser
  setAuth: (user: IAuthUser) => void
  login: (authUser: IAuthUser) => void
  logout: () => void
}

const AuthContext = createContext({} as IAuth)

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const axiosHttp = useAxiosHttp()

  const [auth, setAuth] = useState<IAuthUser>({
    accessToken: null,
    user: null,
  })

  function login(authUser: IAuthUser) {
    setAuth(authUser)
  }

  async function logout() {
    setAuth({ accessToken: null, user: null })
    queryClient.clear()
    navigate(AppRoutes.Login.path())

    try {
      await axiosHttp.post("/auth/logout", {}, { withCredentials: true })
    } catch (error) {
      console.log("Login failed")
    }
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
