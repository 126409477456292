import React from "react"
import { createRoot } from "react-dom/client"

import { App } from "src/App"
// import * as serviceWorkerRegistration from "src/OLDserviceWorkerRegistration"

const rootElement = document.getElementById("root")
const root = createRoot(rootElement) // createRoot(container!) if you use TypeScript

// Sentry.init({
//   enabled: false,
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new BrowserTracing()],
//   environment: process.env.NODE_ENV,
//   release: process.env.NODE_ENV,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

//serviceWorkerRegistration.unregister()

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     window.location.reload()
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: "SKIP_WAITING" })
//     }
//   },
// })
