import { useQuery, UseQueryOptions } from "@tanstack/react-query"

import { TReviewStatus } from "src/data/constants/globalTypes"
import { instalmentKeys } from "src/data/instalment/instalmentQueryKeys"
import {
  IFetchInstalmentSummary,
  IFetchMonthlyInstalment,
} from "src/data/instalment/instalmentTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"
import { cleanQueryKeyDateString } from "src/utils/utils"

const instalmentApi = "/instalment"

export function useFetchInstalmentSummary({
  userId,
  reviewStatus,
  isInterestPaid,
  options,
}: {
  userId?: string
  reviewStatus?: TReviewStatus
  isInterestPaid?: boolean
  options?: Partial<UseQueryOptions<IFetchInstalmentSummary>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInstalmentSummary() {
    const response = await axiosPrivate.get<IFetchInstalmentSummary>(
      `${instalmentApi}/total/summary`,
      { params: { userId, reviewStatus, isInterestPaid } }
    )
    return response.data
  }

  return useQuery({
    queryKey: instalmentKeys.instalmentSummary({ userId, reviewStatus }),
    queryFn: fetchInstalmentSummary,
    ...options,
  })
}

export function useFetchInstalments(filter: {
  skip?: number
  limit?: number
  startDate?: string
  endDate?: string
  reviewStatus?: TReviewStatus
  loanId?: string
  isInterestPaid?: boolean
  options?: Partial<UseQueryOptions<IFetchMonthlyInstalment>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInstalmentSummary() {
    const response = await axiosPrivate.get<IFetchMonthlyInstalment>(
      `${instalmentApi}`,
      { params: { ...filter } }
    )
    return response.data
  }

  return useQuery({
    queryKey: instalmentKeys.instalments({
      ...filter,
      startDate: cleanQueryKeyDateString(filter?.startDate),
      endDate: cleanQueryKeyDateString(filter?.endDate),
    }),
    queryFn: fetchInstalmentSummary,
    ...filter.options,
  })
}
