import styled from "styled-components/macro"

import { MixedChart } from "src/components/Charts/MixedChart"
import { StatisticsCards } from "src/components/Dashboard/StatisticsCards"
import { TransactionsList } from "src/components/Dashboard/TransactionsList"
import { useFetchMonthlyInterestSummary } from "src/data/groupInterest/groupInterestQueries"
import { useFetchInstalmentSummary } from "src/data/instalment/instalmentQueries"
import { useFetchInvestmentSummary } from "src/data/investments/investmentQueries"
import { useFetchLoanSummary } from "src/data/loans/loanQueries"
import { paletteColor } from "src/ui/colors"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { spacing } from "src/ui/spacing"

export function Dashboard() {
  const fetchLoanSummary = useFetchLoanSummary({
    loanStatus: "Active",
  })
  const loanSummary = fetchLoanSummary.data

  const fetchInvestmentSummary = useFetchInvestmentSummary({
    reviewStatus: "Approved",
  })

  const investmentSummary = fetchInvestmentSummary.data

  const fetchInstalmentSummary = useFetchInstalmentSummary({
    reviewStatus: "Approved",
    isInterestPaid: false,
  })
  const instalmentSummary = fetchInstalmentSummary.data

  const fetchMonthlyInterest = useFetchMonthlyInterestSummary({
    reviewStatus: "Approved",
    isInterestPaid: false,
  })
  const monthlyInterestSummary = fetchMonthlyInterest.data

  const investment = investmentSummary?.totalInvestment ?? 0
  const monthlyInterest = monthlyInterestSummary?.totalInterest ?? 0
  const interestFromLoans = instalmentSummary?.totalInterest ?? 0

  const netTotal =
    investment +
    monthlyInterest +
    interestFromLoans -
    (loanSummary?.totalPrinciple ?? 0)

  if (fetchLoanSummary.isLoading || fetchInvestmentSummary.isLoading) {
    return <LoadingScreen />
  }

  if (!loanSummary) {
    return <div>There are no loan totals</div>
  }

  return (
    <>
      <PageTitle pageLabel="Dashboard" />
      <StatisticsCards
        netTotal={netTotal}
        loanTotals={loanSummary}
        investment={investment}
        interestFromLoans={interestFromLoans}
        monthlyInterest={monthlyInterest}
      />

      <Box>
        <MixedChartBox>
          <MixedChart netTotal={netTotal} />
        </MixedChartBox>

        <TransactionsList />
      </Box>
    </>
  )
}

const MixedChartBox = styled.div`
  padding: ${spacing.XL};
  border-radius: 4px;
  border: 1px solid ${paletteColor.divider};
  background-color: #fff;
  min-width: 50%;
`

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  justify-content: space-between;
  gap: ${spacing.L};
`
