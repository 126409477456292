import { ChangeEvent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { LoginWrapper } from "src/components/Login/LoginWrapper"
import { useAuth } from "src/contexts/AuthProvider"
import { usePostLogin } from "src/data/auth/authQueries"
import { breakpoints } from "src/data/constants/breakpoints"
import { usePostRegistrationToken } from "src/data/users/userQueries"
import { saveMessagingDeviceToken } from "src/firebase/messaging"
import { AppRoutes } from "src/router/AppRoutes"
import { MButton } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"
import { Body2, TypographySubtitle1 } from "src/ui/typography"

export function Login() {
  const { setAuth } = useAuth()
  const { state } = useLocation()
  const postregistrationToken = usePostRegistrationToken()
  const navigate = useNavigate()
  const postLogin = usePostLogin()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorMsg, setErrorMsg] = useState("")

  async function handleSubmit(event: ChangeEvent<{}>) {
    event.preventDefault()

    await postLogin
      .mutateAsync(
        { email, password },
        {
          onSuccess: async (data) => {
            setAuth(data)
            navigate(state?.path || AppRoutes.Basic.dashboard, {
              replace: true,
            })
            await saveMessagingDeviceToken(postregistrationToken)
          },
          onError: async (err: any) => {
            if (!err?.response) {
              setErrorMsg("No server response")
            } else if (err?.response.status === 400) {
              setErrorMsg("Invalid username or password")
            } else if (err?.response.status === 401) {
              setErrorMsg("Unauthorised")
            } else {
              setErrorMsg("Login failed")
            }
          },
        }
      )
      .catch((error) => {
        return setErrorMsg("Login failed")
      })
  }

  return (
    <LoginWrapper>
      <Box>
        <Title>GlobalReach Financial Login</Title>

        {errorMsg && <StyledAlert severity="error">{errorMsg}</StyledAlert>}
        <FormBox onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            defaultValue={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <MButton type="submit" loading={postLogin.isPending}>
            Login
          </MButton>
          <Body2>Forgot password? Contact the global administrator</Body2>
        </FormBox>
      </Box>
    </LoginWrapper>
  )
}

const Box = styled.div`
  display: grid;
  margin: ${spacing.M};
  padding: ${spacing.S};
  max-width: 475px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;

  @media (min-width: ${breakpoints.M}) {
    margin: ${spacing.L};
    padding: ${spacing.L};
    width: 45ch;
  }
`

const Title = styled.div`
  margin-bottom: ${spacing.L};
  text-align: center;
  ${TypographySubtitle1};
`

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
  padding-bottom: ${spacing.L};
`

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacing.L};
`
