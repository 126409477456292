import { ReactNode, useState } from "react"
import styled from "styled-components/macro"

import { AppDrawer } from "src/components/AppLayout/AppDrawer"
import { AppFooter } from "src/components/AppLayout/AppFooter"
import { AppHeader } from "src/components/AppLayout/AppHeader"
import { drawerSize } from "src/data/constants/constants"
import { spacing } from "src/ui/spacing"

export function AppLayout({ children }: { children: ReactNode }) {
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <Box>
      <AppDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

      <MainBox>
        <AppHeader openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        <Body>
          <div>{children}</div>
        </Body>
        <AppFooter />
      </MainBox>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
`
const MainBox = styled.main`
  display: block;
  width: calc(100vw - ${drawerSize});
  flex-grow: 1;
  box-sizing: border-box;
  padding: ${spacing.L};
`

const Body = styled.div`
  max-width: 1920px;
  margin: ${spacing.XL2} 0;
`
