import { useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import { usePostTenant } from "src/data/tenants/tenantQueries"
import { MButton } from "src/ui/Button/MButton"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { spacing } from "src/ui/spacing"
import { TypographyHeading5 } from "src/ui/typography"

export function CreateTenant() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [interestRate, setInterestRate] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  })
  const [membership, setMembership] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  })
  const [error, setError] = useState("")

  const postTenant = usePostTenant()

  function handleSubmit() {
    postTenant.mutate(
      {
        fullName: name,
        email,
        password,
        code,
        companyName,
        userId: auth?.user?.userId ?? "",
        interestRate: interestRate.floatValue ?? 0,
        annualMembership: membership.floatValue ?? 0,
      },
      {
        onSuccess: () => navigate(-1),
        onError: () => {
          setError("Something went wrong")
        },
      }
    )
  }

  return (
    <main>
      <PageTitle pageLabel="Create tenant"></PageTitle>
      <StyleForm
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <DetailText> Owner details:</DetailText>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <DetailText> Tenant details:</DetailText>
        <TextField
          label="Tenant code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
        <TextField
          label="Company name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />
        <NumericFormat
          size="small"
          label="Interest rate"
          value={interestRate.formattedValue}
          defaultValue=""
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setInterestRate(values)}
          required
        />

        <NumericFormat
          size="small"
          label="Annual membership"
          value={membership.formattedValue}
          defaultValue=""
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setMembership(values)}
          required
        />

        <MButton type="submit">Create</MButton>
        {error && <Alert severity="error">{error}</Alert>}
      </StyleForm>
    </main>
  )
}

const DetailText = styled.div`
  margin: ${spacing.S} 0 ${spacing.XS} 0;
  ${TypographyHeading5};
`

const StyleForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
  width: 100%;
  max-width: 480px;
`
