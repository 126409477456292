import { useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import { CircularProgress } from "@mui/material"

// import * as Sentry from "@sentry/react"
import { useAuth } from "src/contexts/AuthProvider"
import {
  useDeleteTenant,
  useFetchTenant,
  usePatchTenant,
} from "src/data/tenants/tenantQueries"
import { AppRoutes } from "src/router/AppRoutes"
import { TenantDetailCard } from "src/ui/Cards/TenantDetailCard"
import { MDialog } from "src/ui/Dialog/MDialog"
import { PageTitle } from "src/ui/PageTitle/PageTitle"

export function TenantDetails() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState("")
  const [open, setOpen] = useState(false)

  const fetchTenant = useFetchTenant({
    tenantId: "",
    userId: auth?.user?.userId || "",
  })
  const deleteTenant = useDeleteTenant()
  const updateTenantStatus = usePatchTenant()

  const tenant = fetchTenant.data

  const noTenant = !tenant && !fetchTenant.isPending && fetchTenant.isFetched

  function handleUpdate() {
    tenant &&
      updateTenantStatus.mutate(
        {
          tenantId: tenant.tenantId,
          isDisabled: !tenant.isDisabled,
        },

        {
          onError: (error) => {
            // Sentry.captureException(error)
            setErrorMsg("Could not update tenant status")
          },
        }
      )
  }

  function handleRemove() {
    tenant &&
      deleteTenant.mutate(
        {
          code: tenant.code,
          tenantId: tenant.tenantId,
        },
        {
          onSuccess: () => navigate(AppRoutes.Tenants),
          onError: (error) => {
            // Sentry.captureException(error)
            setErrorMsg("Could not delete tenant")
          },
        }
      )
  }

  return (
    <div>
      <PageTitle pageLabel="Tenant details">
        {fetchTenant.isPending && <StyledCircularProgress />}{" "}
      </PageTitle>

      {tenant ? (
        <TenantDetailCard
          tenant={tenant}
          update={handleUpdate}
          setOpen={setOpen}
        />
      ) : (
        noTenant && <div> Tenant details not available </div>
      )}

      <MDialog
        title={`Delete ${tenant?.companyName}?`}
        body={`This will permanently delete all data related to ${tenant?.companyName}`}
        open={open}
        onClose={() => {
          setOpen(false)
          setErrorMsg("")
        }}
        onConfirm={handleRemove}
        confirmLabel="Delete"
        confirmButtonProps={{ color: "error" }}
        errorText={errorMsg}
      />
    </div>
  )
}

const StyledCircularProgress = styled(CircularProgress)``
