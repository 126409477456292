import styled from "styled-components/macro"

import { Drawer, useMediaQuery } from "@mui/material"

import { SideNav } from "src/components/AppLayout/SideNav"
import { breakpoints } from "src/data/constants/breakpoints"
import { drawerSize } from "src/data/constants/constants"
import { paletteColor } from "src/ui/colors"

export function AppDrawer({
  openDrawer,
  setOpenDrawer,
}: {
  openDrawer: boolean
  setOpenDrawer: (value: boolean) => void
}) {
  const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.S})`, {
    noSsr: true,
  })

  return (
    <StyledNav isMobileScreen={isMobileScreen}>
      <StyledDrawer
        open={isMobileScreen ? openDrawer : false}
        variant={isMobileScreen ? "temporary" : "permanent"}
        onClose={() => setOpenDrawer(false)}
      >
        <SideNav />
      </StyledDrawer>
    </StyledNav>
  )
}

const StyledNav = styled.nav<{ isMobileScreen: boolean }>`
  flex-shrink: 1;
  width: ${(props) => (props.isMobileScreen ? `0px` : drawerSize)};
`

const StyledDrawer = styled(Drawer)`
  display: block;
  & .MuiDrawer-paper {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    width: ${drawerSize};
    border-right: 1px solid ${paletteColor.divider};
    background-image: none;
    box-shadow: inherit;
    /* add overrides here */
  }
`
