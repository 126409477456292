import axios, { ParamsSerializerOptions } from "axios"
import qs from "qs"

const isProd = process.env.NODE_ENV === "production"

const APP_PATH = isProd
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV

const DEFAULT_API = apiPath("api/v2")

function paramsSerializer(params: ParamsSerializerOptions) {
  return qs.stringify(params, {
    arrayFormat: "comma",
    skipNulls: true,
  })
}

// const axiosHttpClient = axios.create({
//   baseURL: DEFAULT_API,
//   headers: { "Content-Type": "application/json" },
//   withCredentials: true,
//   paramsSerializer: paramsSerializer,
// })

export const baseHttpClient = axios.create({
  baseURL: DEFAULT_API,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  paramsSerializer: paramsSerializer,
})

// export function setApiClientAccessToken(accessToken: string) {
//   axiosHttpClient.defaults.headers.common[
//     "Authorization"
//   ] = `Bearer ${accessToken}`
// }

function apiPath(path: string) {
  return `${APP_PATH}/${path}`
}
