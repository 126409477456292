import { useState } from "react"
import styled from "styled-components/macro"

import { Autocomplete, Checkbox, TextField } from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import { UserRole } from "src/data/roles/roles"
import { usePatchUser } from "src/data/users/userQueries"
import { IUser } from "src/data/users/userTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ReactComponent as CheckBoxIcon } from "src/ui/icons/CheckBox.svg"
import { ReactComponent as CheckBoxOutlineBlankIcon } from "src/ui/icons/CheckBoxOutlineBlank.svg"
import { spacing } from "src/ui/spacing"

export function UpdateUser({
  user,
  open,
  setOpen,
}: {
  user: IUser
  open: boolean
  setOpen: (value: boolean) => void
}) {
  const updateUserFormId = "update-user-form-id"
  const { auth } = useAuth()
  const [name, setName] = useState(user.name)
  const [role, setRole] = useState(user.role)
  const roleOptions = Object.values(UserRole)

  const patchUser = usePatchUser()

  function handleFormSubmit() {
    try {
      patchUser.mutate(
        { userId: user.userId, name, role },
        {
          onSuccess: () => {
            setOpen(false)
          },
        }
      )
    } catch {
      console.log("There was an error updateing the user")
    }
  }

  return (
    <MDialog
      title="Update user"
      open={open}
      confirmLabel="Update user"
      loading={patchUser.isPending}
      onConfirm={handleFormSubmit}
      onClose={() => setOpen(false)}
      errorText={
        patchUser.isError
          ? "There was an error while updating your information"
          : ""
      }
    >
      <UserDetailsBox> User details</UserDetailsBox>
      <FormBox onSubmit={handleFormSubmit} id={updateUserFormId}>
        <TextField
          label="Full name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <Autocomplete
          multiple
          id="user-roles"
          value={role}
          disabled={auth?.user?.userId === user.userId}
          options={roleOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={(e, v) => setRole(v)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Roles" placeholder="Roles" />
          )}
        />
      </FormBox>
    </MDialog>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const UserDetailsBox = styled.div`
  margin-bottom: ${spacing.L};
`
