import styled from "styled-components/macro"

import { paletteColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { TypographyBody2 } from "src/ui/typography"

export function AppFooter() {
  return (
    <StyledFooter>
      &copy; {new Date().getFullYear()} Group Finance Inc
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  padding: ${spacing.XS};
  height: 20px;
  justify-content: center;
  background-color: #fff;
  border-top: 1px solid ${paletteColor.secondary.light};
  ${TypographyBody2};
  z-index: 10;
`
