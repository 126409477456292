import { intlFormat, lightFormat, parseISO } from "date-fns"

import { TColor } from "src/components/MChip/MChip"
import { TReviewStatus } from "src/data/constants/globalTypes"
import { Status } from "src/data/general/generalTypes"
import { TLoanStatus } from "src/data/loans/loanTypes"

export function trimString(value: string) {
  return value.replace(/\s+/g, " ").trim()
}

export function getDateOnly(zuluDate: string) {
  if (!zuluDate) {
    return zuluDate
  }

  const date = intlFormat(new Date(parseISO(zuluDate)), {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return date
}

export function statusToColorProfile(
  status: TLoanStatus | TReviewStatus | "Error"
): TColor {
  if (status === Status.APPROVED) return "success"
  if (status === Status.ACTIVE) return "primary"
  if (status === Status.PAID) return "success"
  if (status === Status.PENDING) return "warning"
  return "error"
}

export function cleanObjectConvertToMap<T extends Object>(obj: T) {
  const filterUndefined = Object.entries(obj).filter(
    ([, value]) => value !== undefined
  )

  return Object.fromEntries(filterUndefined)
}

export function cleanQueryKeyDateString(date?: string) {
  if (!date) return date

  return lightFormat(new Date(date), "yyyy-MM-dd HH:mm:ss")
}
