import { useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import { usePostMembership } from "src/data/memberships/membershipQueries"
import { useFetchTenant } from "src/data/tenants/tenantQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"
import { Subtitle2 } from "src/ui/typography"

export function PayMembership({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (status: boolean) => void
}) {
  const { auth } = useAuth()
  const [amount, setAmount] = useState<NumberFormatValues>({
    floatValue: 0,
    formattedValue: "0",
    value: "0",
  })
  const postMembership = usePostMembership()
  const fetchTenant = useFetchTenant({
    tenantId: "",
    userId: auth?.user?.userId || "",
  })
  const tenant = fetchTenant.data

  if (!tenant) {
    return <>Waiting for tenant data</>
  }
  const membershipAmount = tenant.annualMembership

  function handleMembershipPayment() {
    tenant &&
      postMembership.mutate(
        {
          amount: amount.floatValue || 0,
        },
        { onSuccess: () => setOpen(false) }
      )
  }

  return (
    <MDialog
      open={open}
      title="Pay your administration fee"
      onClose={() => {
        setOpen(false)
        setAmount({
          floatValue: 0,
          formattedValue: "0",
          value: "0",
        })
        postMembership.reset()
      }}
      confirmLabel="Pay administration fee"
      onConfirm={handleMembershipPayment}
      confirmButtonProps={{
        disabled:
          postMembership.isPending ||
          membershipAmount - (amount.floatValue ?? 0) !== 0 ||
          !amount.floatValue,
      }}
    >
      <StyledMBox>
        <NumericFormat
          size="small"
          label=" Amount"
          value={amount.formattedValue}
          defaultValue=""
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setAmount(values)}
          required
        />

        <Subtitle2>
          <div>
            You are to pay: UGX {membershipAmount.toLocaleString("en-GB")}
          </div>
          Outstanding: UGX{" "}
          {(membershipAmount - (amount.floatValue ?? 0)).toLocaleString(
            "en-GB"
          )}
        </Subtitle2>
        {membershipAmount - (amount.floatValue ?? 0) < 0 && (
          <Alert severity="error">
            You cannot pay more than the annual administration fee amount
          </Alert>
        )}

        {postMembership.isError && (
          <Alert severity="warning">There was a problem saving your data</Alert>
        )}
      </StyledMBox>
    </MDialog>
  )
}

const StyledMBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  align-items: center;
`
