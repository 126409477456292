import { ReactNode } from "react"
import styled from "styled-components/macro"

import {
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material"
import { Variant } from "@mui/material/styles/createTypography"

import { MButton } from "src/ui/Button/MButton"
import { paletteColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { TypographyBody2 } from "src/ui/typography"

export function MCard({
  title,
  action,
  variant = "body1",
  onConfirm,
  confirmLabel = "Ok",
  confirmButtonProps,
  onCancel,
  cancelLabel = "Cancel",
  cancelButtonProps,
  loading,
  content,
  children,
  ...props
}: {
  title: ReactNode
  action?: ReactNode
  variant?: Variant
  onConfirm?: () => void
  confirmLabel?: ReactNode
  confirmButtonProps?: ButtonProps
  onCancel?: () => void
  cancelLabel?: ReactNode
  cancelButtonProps?: ButtonProps
  loading?: boolean
  content?: ReactNode
  children?: ReactNode
}) {
  return (
    <StyledCard sx={{ boxShadow: "unset" }} {...props}>
      <StyledCardHeader
        titleTypographyProps={{ variant }}
        title={title}
        action={action}
      />
      <Divider />

      <StyledCardContent>{content ?? children}</StyledCardContent>

      {(onConfirm || onCancel) && (
        <>
          <Divider />
          <ActionBox>
            {onCancel && (
              <MButton
                onClick={onCancel}
                variant="outlined"
                {...cancelButtonProps}
              >
                {cancelLabel}
              </MButton>
            )}

            {onConfirm && (
              <MButton
                onClick={onConfirm}
                loading={loading}
                {...confirmButtonProps}
              >
                {confirmLabel}
              </MButton>
            )}
          </ActionBox>
        </>
      )}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  border: 1px solid ${paletteColor.divider};
  border-radius: 2px;
  //width: max-content;
  ${TypographyBody2};
`

const StyledCardHeader = styled(CardHeader)`
  padding: ${spacing.M};
`

const StyledCardContent = styled(CardContent)`
  padding: ${spacing.M};
`

const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.M};
`
