// import * as Sentry from "@sentry/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { tenantKeys } from "src/data/tenants/tenantQueryCache"
import {
  IDeleteTenant,
  IFetchTenants,
  IPostTenant,
  IPostUpdateTenant,
  ITenant,
} from "src/data/tenants/tenantTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

const TenantsAPI = "/tenants"

export function useFetchTenants() {
  const axiosPrivate = useAxiosHttp()
  async function fetchTenants(): Promise<IFetchTenants> {
    const response = await axiosPrivate.get<IFetchTenants>(`${TenantsAPI}`)
    return response.data
  }

  return useQuery({ queryKey: tenantKeys.all(), queryFn: fetchTenants })
}

export function usePostTenant() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function postTenant(tenantDetails: IPostTenant) {
    const response = await axiosPrivate.post("/tenants/create", tenantDetails)
    return response.data
  }

  return useMutation({
    mutationFn: postTenant,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: tenantKeys.all() })
    },
    onError: (error) => {
      console.log({ error })
      // Sentry.captureException(error)
    },
  })
}

export function useFetchTenant({
  tenantId,
  userId,
}: {
  tenantId: string
  userId: string
}) {
  const axiosPrivate = useAxiosHttp()
  async function fetchTenant(): Promise<ITenant> {
    const response = await axiosPrivate.get<ITenant>(
      `${TenantsAPI}/${tenantId}/user/${userId}`
    )
    return response.data
  }

  return useQuery({
    queryKey: tenantKeys.tenant(tenantId),
    queryFn: fetchTenant,
    enabled: !!tenantId && !!userId,
  })
}

export function usePatchTenant() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function patchTenant(tenantDetails: IPostUpdateTenant) {
    const response = await axiosPrivate.patch<ITenant>(
      `${TenantsAPI}/update`,
      tenantDetails
    )
    return response.data
  }

  return useMutation({
    mutationFn: patchTenant,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: tenantKeys.all({}) })
    },
    onError: (error) => {
      // Sentry.captureException(error)
    },
  })
}

export function useDeleteTenant() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function deleteTenant(tenant: IDeleteTenant) {
    const response = await axiosPrivate.delete<{
      success: boolean
    }>(`${TenantsAPI}/${tenant.code}/delete/${tenant.tenantId}`)
    return response.data
  }

  return useMutation({
    mutationFn: deleteTenant,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tenantKeys.all() })
    },
    onError: (error) => {
      // Sentry.captureException(error)
    },
  })
}
