import Chart from "react-apexcharts"

import { ApexOptions } from "apexcharts"
import {
  eachMonthOfInterval,
  format,
  getMonth,
  getYear,
  subMonths,
} from "date-fns"

import { useFetchMonthlyInterest } from "src/data/groupInterest/groupInterestQueries"
import { IMonthlyInterest } from "src/data/groupInterest/groupInterestTypes"
import { useFetchInstalments } from "src/data/instalment/instalmentQueries"
import { IInstalment } from "src/data/instalment/instalmentTypes"
import { useFetchInvestments } from "src/data/investments/investmentQueries"
import { IInvestment } from "src/data/investments/investmentTypes"
import { useFetchLoans } from "src/data/loans/loanQueries"
import { IFetchLoan } from "src/data/loans/loanTypes"
import { paletteColor } from "src/ui/colors"

export function MixedChart({ netTotal }: { netTotal: number }) {
  const today = new Date()
  const startDate = subMonths(today, 11)

  const intervalMonths = eachMonthOfInterval({
    start: subMonths(today, 11),
    end: today,
  })

  const fetchMonthlyInterest = useFetchMonthlyInterest({
    startDate: startDate.toISOString(),
    endDate: today.toISOString(),
    options: { enabled: !!startDate && !!today },
  })

  const fetchInvestments = useFetchInvestments({
    startDate: startDate.toISOString(),
    endDate: today.toISOString(),
    options: { enabled: !!startDate && !!today },
  })

  const fetchLoans = useFetchLoans({
    startDate: startDate.toISOString(),
    endDate: today.toISOString(),
    options: { enabled: !!startDate && !!today },
  })

  const fetchInstalments = useFetchInstalments({
    startDate: startDate.toISOString(),
    endDate: today.toISOString(),
    options: { enabled: !!startDate && !!today },
  })

  const investments = fetchInvestments.data?.investments || []
  const monthlyInterest = fetchMonthlyInterest.data?.interest || []
  const loansData = fetchLoans.data?.loans || []
  const instalmentsData = fetchInstalments.data?.instalment || []

  const chartData = getChartData({
    months: intervalMonths,
    monthlyInterest: monthlyInterest,
    investment: investments,
    loans: loansData,
    instalments: instalmentsData,
  })

  const seriesMixedChart = [
    {
      name: "Investments",
      type: "line",
      data: chartData.investments,
    },
    {
      name: "Interest",
      type: "column",
      data: chartData.interest,
    },
    {
      name: "Loans",
      type: "bar",
      data: chartData.loans,
    },
    {
      name: "Interest from loans",
      type: "bar",
      data: chartData.interestLoans,
    },
  ]

  const options: ApexOptions = {
    chart: {
      id: "group-finance",
    },
    xaxis: {
      //type: "datetime",
      categories: chartData.months,
    },
    yaxis: { tickAmount: 4, min: 0, decimalsInFloat: 0 },
    stroke: {
      width: [1.5, 1],
    },
    tooltip: {
      y: { formatter: (value) => `UGX ${value.toLocaleString()}` },
    },
    colors: [
      paletteColor.primary.main,
      paletteColor.success.main,
      paletteColor.error.main,
      paletteColor.warning.main,
    ],
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    annotations: {
      yaxis: [
        {
          y: netTotal,
          borderColor: paletteColor.error.main,
          label: {
            borderColor: paletteColor.error.lighter,
            style: {
              color: "#FFF",
              background: paletteColor.error.main,
            },
            text: `Net total: ${netTotal.toLocaleString()}`,
          },
        },
      ],
    },
  }

  return (
    <Chart
      options={options}
      series={seriesMixedChart}
      width="100%"
      height={400}
    />
  )
}

function getChartData({
  months,
  monthlyInterest,
  investment,
  loans,
  instalments,
}: {
  months: Date[]
  monthlyInterest: IMonthlyInterest[]
  investment: IInvestment[]
  loans: IFetchLoan["loans"]
  instalments: IInstalment[]
}) {
  const mInterest: number[] = []
  const mInvestments: number[] = []
  const mLoans: number[] = []
  const mILoans: number[] = []
  const mMonths: string[] = []

  months.forEach((month) => {
    const currMonth = getMonth(month)
    const currYear = getYear(month)

    const monthlyInvestment = investment.reduce((acc, investment) => {
      const interestMonth = getMonth(new Date(investment.createdAt))
      const interestYear = getYear(new Date(investment.createdAt))
      if (currMonth === interestMonth && currYear === interestYear) {
        return (acc += investment.amount)
      }
      return acc
    }, 0)

    const monthlyInterestTotal = monthlyInterest.reduce((acc, interest) => {
      const interestMonth = getMonth(new Date(interest.createdAt))
      const interestYear = getYear(new Date(interest.createdAt))

      if (currMonth === interestMonth && currYear === interestYear) {
        return (acc += interest.amount)
      }

      return acc
    }, 0)

    const monthlyLoans = loans.reduce((acc, loan) => {
      const interestMonth = getMonth(new Date(loan.createdAt))
      const interestYear = getYear(new Date(loan.createdAt))
      if (currMonth === interestMonth && currYear === interestYear) {
        return (acc += loan.amount)
      }

      return acc
    }, 0)

    const monthlyInterestLoans = instalments.reduce((acc, instalment) => {
      const interestMonth = getMonth(new Date(instalment.createdAt))
      const interestYear = getYear(new Date(instalment.createdAt))
      if (currMonth === interestMonth && currYear === interestYear) {
        return (acc += instalment.interest)
      }

      return acc
    }, 0)

    mInterest.push(monthlyInterestTotal)
    mInvestments.push(monthlyInvestment)
    mLoans.push(monthlyLoans)
    mILoans.push(monthlyInterestLoans)
    mMonths.push(format(month, "MMM"))
  })

  return {
    months: mMonths,
    interest: mInterest,
    investments: mInvestments,
    loans: mLoans,
    interestLoans: mILoans,
  }
}
