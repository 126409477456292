export const spacing = {
  XS2: "0.25rem",
  XS: "0.5rem",
  S: "0.75rem",
  M: "1rem",
  L: "1.5rem",
  XL: "2rem",
  XL2: "2.5rem",
  XL3: "3rem",
  XL4: "4rem",
}
