import styled from "styled-components/macro"

import { InterestPayout } from "src/components/Admin/InterestPayout"
import { TenantSetup } from "src/components/Admin/TenantSetup"
import { useAuth } from "src/contexts/AuthProvider"
import { useFetchOrganisation } from "src/data/organisation/organisationQueries"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { spacing } from "src/ui/spacing"

export function Admin() {
  const { auth } = useAuth()

  const fetchOrganisation = useFetchOrganisation({
    organisationId: auth?.user?.organisationId || "",
    options: { enabled: !!auth?.user?.organisationId },
  })
  const organisation = fetchOrganisation.data

  if (fetchOrganisation.isLoading) {
    return <LoadingScreen />
  }

  return (
    <Box>
      <TenantSetup organisation={organisation} user={auth.user} />

      <InterestPayout />
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`
