import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"

import { TReviewStatus } from "src/data/constants/globalTypes"
import {
  IFetchMonthlyInterest,
  IFetchMonthlyInterestSummary,
  IInterestByDate,
  IPostInterestRequest,
  IPostInterestResponse,
} from "src/data/groupInterest/groupInterestTypes"
import { interestKeys } from "src/data/groupInterest/interestQueriesCache"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"
import { cleanQueryKeyDateString } from "src/utils/utils"

const interestAPI = "/interest"

export function usePostInterest() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function postInterest(interest: IPostInterestRequest) {
    const response = await axiosPrivate.post<IPostInterestResponse>(
      `${interestAPI}`,
      {
        ...interest,
      }
    )
    return response.data
  }

  return useMutation<IPostInterestResponse, Error, IPostInterestRequest>({
    mutationFn: postInterest,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: interestKeys.all() }),
  })
}

export function useFetchMonthlyInterest(filter: {
  skip?: number
  limit?: number
  startDate?: string
  endDate?: string
  reviewStatus?: TReviewStatus
  userId?: string
  isInterestPaid?: boolean
  options?: Partial<UseQueryOptions<IFetchMonthlyInterest>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchMonthlyInterest() {
    const response = await axiosPrivate.get<IFetchMonthlyInterest>(
      `${interestAPI}`,
      {
        params: {
          ...filter,
        },
      }
    )
    return response.data
  }

  return useQuery({
    queryKey: interestKeys.interest({
      ...filter,
      startDate: cleanQueryKeyDateString(filter?.startDate),
      endDate: cleanQueryKeyDateString(filter?.endDate),
    }),
    queryFn: fetchMonthlyInterest,
    ...filter.options,
  })
}

export function useFetchInterestByDate({
  tenantId,
  startDate,
  endDate,
}: {
  tenantId: string
  startDate?: string
  endDate?: string
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInvestmentsByDate() {
    const response = await axiosPrivate.get<IInterestByDate[]>(
      `${interestAPI}/tenant/${tenantId}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  }

  return useQuery({
    queryKey: interestKeys.interestDate(tenantId),
    queryFn: fetchInvestmentsByDate,
    // enabled: !!tenantId,
  })
}

interface ICombinedInterestResponse {
  combinedInterestSum: number
  groupInterest: {
    list: IInterestByDate
    sum: number
  }
  interestFromLoans: {
    list: IInterestByDate
    sum: number
  }
}

export function useFetchCombinedInterestByDate({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInvestmentsByDate() {
    const response = await axiosPrivate.get<ICombinedInterestResponse>(
      `${interestAPI}/combinedInterest?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  }

  return useQuery({
    queryKey: interestKeys.combinedInterestByDate({ startDate, endDate }),
    queryFn: fetchInvestmentsByDate,
    enabled: !!startDate && !!endDate,
  })
}

export function usePostInterestPayment() {
  const axiosPrivate = useAxiosHttp()

  async function postInterestPayment({
    interestAmount,
    startDate,
    endDate,
  }: {
    interestAmount: number
    startDate: string
    endDate: string
  }) {
    const response = await axiosPrivate.post(`${interestAPI}/payment/notify`, {
      interestAmount,
      startDate,
      endDate,
    })
    return response.data
  }

  return useMutation({
    mutationFn: postInterestPayment,
  })
}

export function usePatchInitialInterestPaymentState() {
  const axiosPrivate = useAxiosHttp()

  async function patchInitialInterestPayment() {
    const response = await axiosPrivate.patch(
      `${interestAPI}/payment/setInitial`
    )
    return response.data
  }

  return useMutation({
    mutationFn: patchInitialInterestPayment,
  })
}

export function useFetchMonthlyInterestSummary({
  userId,
  reviewStatus,
  isInterestPaid,
  options,
}: {
  userId?: string
  reviewStatus?: TReviewStatus
  isInterestPaid?: boolean
  options?: Partial<UseQueryOptions<IFetchMonthlyInterestSummary>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchMonthlyInterestSummary() {
    const response = await axiosPrivate.get<IFetchMonthlyInterestSummary>(
      `${interestAPI}/total/summary`,
      { params: { userId, reviewStatus, isInterestPaid } }
    )
    return response.data
  }

  return useQuery({
    queryKey: interestKeys.interestSummary({ userId, reviewStatus }),
    queryFn: fetchMonthlyInterestSummary,
    ...options,
  })
}
