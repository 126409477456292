import { ChangeEvent, useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import styled from "styled-components/macro"

import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { format, getYear } from "date-fns"

import { IAuthUser } from "src/contexts/AuthProvider"
import { Month } from "src/data/general/generalTypes"
import { usePostInterest } from "src/data/groupInterest/groupInterestQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

export function AddGroupInterest({
  open,
  setOpen,
  auth,
}: {
  open: boolean
  setOpen: (status: boolean) => void
  auth: IAuthUser
}) {
  const postInterest = usePostInterest()
  const [amount, setAmount] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  })
  const [year, setYear] = useState(getYear(Date.now()))
  const [comment, setComment] = useState("")
  const [month, setMonth] = useState<Month>(format(Date.now(), "MMMM") as Month)
  const [errorText, setErrorText] = useState("")

  const handleMonthChange = (event: SelectChangeEvent<Month>) => {
    setMonth(event.target.value as Month)
  }

  function handleChangeYear(
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const value = e.target.value

    if (isNaN(parseInt(value))) {
      return
    }

    setYear(parseInt(value))
  }

  function handlePostInterest() {
    if (!amount.floatValue) {
      return setErrorText("Missing required values")
    }

    return (
      auth?.user &&
      postInterest.mutate(
        {
          amount: amount.floatValue,
          month,
          year,
          comment,
        },
        {
          onSuccess: () => {
            setOpen(false)
            setErrorText("")
          },
        }
      )
    )
  }

  return (
    <MDialog
      open={open}
      title="Add monthly group interest earned"
      onClose={() => {
        setOpen(false)
        setAmount({
          floatValue: undefined,
          formattedValue: "",
          value: "",
        })
        postInterest.reset()
      }}
      confirmLabel="Add group interest"
      onConfirm={handlePostInterest}
      confirmButtonProps={{
        disabled: !amount?.floatValue || amount.floatValue <= 0,
      }}
      errorText={
        !!errorText || postInterest.isError
          ? "There was a problem saving your group interest"
          : ""
      }
    >
      <StyledMBox>
        <NumericFormat
          label="Group interest"
          value={amount.formattedValue}
          defaultValue="0"
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setAmount(values)}
          required
        />
        <InterestPeriodBox>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month}
              label="Month"
              onChange={handleMonthChange}
            >
              {Object.values(Month).map((mon) => (
                <MenuItem key={mon} value={mon}>
                  {mon}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Year"
            required
            value={year}
            onChange={handleChangeYear}
          />
        </InterestPeriodBox>

        <TextField
          fullWidth
          label="Comment"
          value={comment}
          onChange={(event) => setComment(event.currentTarget.value)}
        />

        {postInterest.isError && (
          <Alert severity="warning">There was a problem saving your data</Alert>
        )}
      </StyledMBox>
    </MDialog>
  )
}

const StyledMBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  align-items: center;
`

const InterestPeriodBox = styled.div`
  display: flex;
  gap: ${spacing.XL};
  align-items: center;
`
