import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"

import { TReviewStatus } from "src/data/constants/globalTypes"
import { annualFeeKeys } from "src/data/memberships/membershipQueriesCache"
import {
  IAnnualFee,
  IFetchAnnualFee,
  IUserMembership,
} from "src/data/memberships/membershipTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

const annuaFee = "/fee"

export function usePostMembership() {
  const axiosPrivate = useAxiosHttp()
  async function postMembership({ amount }: { amount: number }) {
    const response = await axiosPrivate.post("/memberships", { amount })

    return response.data
  }

  return useMutation({ mutationFn: postMembership })
}

export function useFetchMembership(userId: string) {
  const axiosPrivate = useAxiosHttp()
  async function fetchMembership() {
    const response = await axiosPrivate.get<IUserMembership>(
      `${annuaFee}/${userId}`
    )
    return response.data
  }

  return useQuery({
    queryKey: annualFeeKeys.membership(userId),
    queryFn: fetchMembership,
    enabled: !!userId,
  })
}

export function useFetchAnnualFee({
  skip,
  limit,
  userIds,
  reviewStatus,
  year,
  options,
}: {
  skip?: number
  limit?: number
  userIds?: string[]
  reviewStatus?: TReviewStatus
  year?: number
  options?: Partial<UseQueryOptions<IFetchAnnualFee>>
}) {
  const axiosPrivate = useAxiosHttp()
  async function fetchAnnualFee() {
    const response = await axiosPrivate.get<IFetchAnnualFee>(`${annuaFee}`, {
      params: { userIds, skip, limit, reviewStatus, year },
    })

    return response.data
  }

  return useQuery({
    queryKey: annualFeeKeys.all({ skip, limit }),
    queryFn: fetchAnnualFee,
    ...options,
  })
}

export function usePatchMembershipAmount() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function patchMembershipAmount({
    membershipId,
    amount,
  }: {
    membershipId: string
    amount: number
  }) {
    const response = await axiosPrivate.patch<IAnnualFee>(
      `${annuaFee}/${membershipId}/amount`,
      { amount }
    )

    return response.data
  }

  return useMutation<
    IAnnualFee,
    Error,
    { membershipId: string; amount: number }
  >({
    mutationFn: patchMembershipAmount,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: annualFeeKeys.all({}) }),
  })
}
