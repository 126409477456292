import { forwardRef, Ref } from "react"
import styled from "styled-components/macro"

import { Button, ButtonProps, CircularProgress } from "@mui/material"

import { boxShadow } from "src/data/constants/boxShadow"

interface IMButtonProps extends ButtonProps {
  loading?: boolean
  progressSize?: string
  borderRadius?: string
}

function RefButton(
  {
    loading,
    disabled,
    progressSize = "1rem",
    disableElevation = true,
    borderRadius = "4px",
    color = "primary",
    variant = "contained",
    size = "large",
    children,
    ...props
  }: IMButtonProps,
  ref: Ref<any>
) {
  return (
    <div>
      <StyledButton
        ref={ref}
        variant={variant}
        color={color}
        disabled={loading || disabled}
        disableElevation={disableElevation}
        size={size}
        sx={{ borderRadius: borderRadius }}
        {...props}
      >
        {loading && <StyledCircularProgress size={progressSize} />}
        {children}
      </StyledButton>
    </div>
  )
}

export const MButton = forwardRef(RefButton)

const StyledButton = styled(Button)`
  overflow: hidden;
  white-space: nowrap;
  min-width: 100rem;
  text-transform: none;
  //drop-shadow: ${boxShadow.XL2};
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
`
