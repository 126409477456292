import { useState } from "react"
import styled from "styled-components/macro"

import { AddInvestment } from "src/components/Investments/AddInvestment"
import { MChip } from "src/components/MChip/MChip"
import { MPagination } from "src/components/Pagination/MPagination"
import { MTable } from "src/components/Table/MTable"
import { useAuth } from "src/contexts/AuthProvider"
import { MAX_LIMIT } from "src/data/constants/constants"
import { useFetchInvestments } from "src/data/investments/investmentQueries"
import { UserRole } from "src/data/roles/roles"
import { useFetchUsers } from "src/data/users/userQueries"
import { MButton } from "src/ui/Button/MButton"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { Caption } from "src/ui/typography"
import { getDateOnly, statusToColorProfile } from "src/utils/utils"

export function Investments() {
  const { auth } = useAuth()
  const [open, setOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const limit = MAX_LIMIT
  const fetchInvestments = useFetchInvestments({ skip: offset, limit })
  const investments = fetchInvestments.data?.investments || []

  const userIds = investments.map((inv) => inv.userId)

  const fetchUsers = useFetchUsers({
    userIds,
    options: { enabled: userIds.length > 0 },
  })

  const users = fetchUsers.data?.users || []

  const headerData = [
    "Name",
    "Investment period",
    "Amount (UGX)",
    "Status",
    "Last updated",
    "Comment",
  ]
  const header = headerData.map((item) => <div>{item}</div>)
  const rows = investments?.map((investment) => {
    const reqDate = getDateOnly(investment.createdAt)
    const updatedDate = getDateOnly(investment.updatedAt)

    const user = users.find((user) => user.userId === investment.userId)

    return (
      <>
        <NameBox>
          {user?.name ?? "Unknown user"}
          <Caption>{reqDate}</Caption>
        </NameBox>
        <div>
          {investment.month} {investment.year}
        </div>
        <div>{investment.amount.toLocaleString()}</div>

        <div>
          <MChip
            label={investment.reviewStatus}
            color={statusToColorProfile(investment.reviewStatus)}
          />
        </div>
        <div>{updatedDate}</div>
        <div>{investment.comment}</div>
      </>
    )
  })

  if (fetchInvestments.isPending || fetchUsers.isLoading) {
    return <LoadingScreen />
  }

  return (
    <Box>
      <PageTitle pageLabel="Investments"></PageTitle>

      <MTable
        title="Status"
        header={header}
        rows={rows}
        templateColumns={`repeat( 6, minmax(min-content, 1fr))`}
        action={
          auth?.user?.role.includes(UserRole.USER) && (
            <MButton size="small" onClick={() => setOpen(true)}>
              Add investment
            </MButton>
          )
        }
        pagination={
          <MPagination
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchInvestments.data?.totalCount || 0}
          />
        }
      />

      {!!auth && (
        <AddInvestment
          key={`${open}`}
          open={open}
          setOpen={setOpen}
          auth={auth}
        />
      )}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
`

const NameBox = styled.div`
  display: flex;
  min-width: max-content;
`
