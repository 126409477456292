import styled from "styled-components/macro"

import { LinearProgress } from "@mui/material"

import { ILoan } from "src/data/loans/loanTypes"
import { paletteColor } from "src/ui/colors"
import { ReactComponent as ClearIcon } from "src/ui/icons/Clear.svg"
import { ReactComponent as ScheduleIcon } from "src/ui/icons/Schedule.svg"
import { spacing } from "src/ui/spacing"
import {
  Caption1,
  Caption2,
  Subtitle2,
  TypographySubtitle2,
} from "src/ui/typography"

export function LoanCard({ loan }: { loan: ILoan }) {
  return (
    <Box>
      <RowBox key={loan.loanId}>
        <StatusBox>
          <StatusIcon interestPaid={loan.interest} />
        </StatusBox>
        <InfoBox>
          <MessageBox>
            {new Date(loan.createdAt).toLocaleDateString("en-GB", {
              dateStyle: "medium",
            })}
            <Caption1>{loan.comment}</Caption1>
          </MessageBox>

          <Caption2>
            Paid: UGX {loan.amortisation.toLocaleString()} of{" "}
            {loan.amount.toLocaleString()}
          </Caption2>
        </InfoBox>

        <IndicatorBox>
          <div> UGX{loan.amount.toLocaleString()}</div>
          <Caption2>
            {((loan.amortisation / loan.amount) * 100).toFixed(2)} %
          </Caption2>
        </IndicatorBox>
      </RowBox>
      <LinearProgress
        variant="determinate"
        value={(loan.amortisation / loan.amount) * 100}
      />
    </Box>
  )
}

function StatusIcon({ interestPaid }: { interestPaid: number }) {
  if (interestPaid > 0) {
    return (
      <IconBox bgColor={paletteColor.warning.lighter}>
        <ScheduleIcon fill={paletteColor.warning.main} />
      </IconBox>
    )
  }

  return (
    <IconBox bgColor={paletteColor.error.lighter}>
      <ClearIcon fill={paletteColor.error.main} />
    </IconBox>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XS};
  padding: ${spacing.XS};
`

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.L};
`

const IconBox = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${paletteColor.divider};
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
`

const MessageBox = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${TypographySubtitle2};
`

const InfoBox = styled.div`
  display: grid;
  justify-content: flex-start;
  width: 100%;
  ${TypographySubtitle2};
`

const StatusBox = styled.div`
  display: grid;
  align-items: center;
`

const IndicatorBox = styled(Subtitle2)`
  display: grid;
  align-content: space-between;
  text-align: right;
`
