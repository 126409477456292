import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"

import { TReviewStatus } from "src/data/constants/globalTypes"
import { instalmentKeys, loanKeys } from "src/data/loans/loanQueriesCache"
import {
  IFetchLoan,
  IFetchLoanSummary,
  IFetchPersonalLoan,
  IInstalmentsByDate,
  ILoansByDate,
  IPostInstalmentRequest,
  IPostInstalmentResponse,
  IPostLoanRequest,
  IPostLoanResponse,
  TLoanStatus,
} from "src/data/loans/loanTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"
import { cleanQueryKeyDateString } from "src/utils/utils"

const loanAPI = "/loan"
const instalmentAPI = "/instalment"

export function usePostLoan() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function postLoan(loan: IPostLoanRequest) {
    const response = await axiosPrivate.post<IPostLoanResponse>(`${loanAPI}`, {
      ...loan,
    })
    return response.data
  }

  return useMutation<IPostLoanResponse, Error, IPostLoanRequest>({
    mutationFn: postLoan,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: loanKeys.all() }),
  })
}

export function useFetchLoans(filter: {
  skip?: number
  limit?: number
  userId?: string
  reviewStatus?: TReviewStatus
  loanStatus?: TLoanStatus
  startDate?: string
  endDate?: string
  options?: Partial<UseQueryOptions<IFetchLoan>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchLoans() {
    const response = await axiosPrivate.get<IFetchLoan>(`${loanAPI}`, {
      params: {
        ...filter,
      },
    })
    return response.data
  }

  return useQuery({
    queryKey: loanKeys.loans({
      ...filter,
      startDate: cleanQueryKeyDateString(filter?.startDate),
      endDate: cleanQueryKeyDateString(filter?.endDate),
    }),
    queryFn: fetchLoans,
    ...filter.options,
  })
}

export function useFetchLoanSummary({
  userId,
  loanStatus,
  reviewStatus,
  options,
}: {
  userId?: string
  loanStatus?: TLoanStatus
  reviewStatus?: TReviewStatus
  options?: Partial<UseQueryOptions<IFetchLoanSummary>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchLoanSummary() {
    const response = await axiosPrivate.get<IFetchLoanSummary>(
      `${loanAPI}/total/summary`,
      { params: { userId, loanStatus, reviewStatus } }
    )
    return response.data
  }

  return useQuery({
    queryKey: loanKeys.loanSummary({ loanStatus, reviewStatus }),
    queryFn: fetchLoanSummary,
    ...options,
  })
}

export function useFetchPersonalLoans({
  userId,
  skip = 0,
  limit = 5,
}: {
  userId: string
  skip?: number
  limit?: number
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchPersonalLoans() {
    const response = await axiosPrivate.get<IFetchPersonalLoan>(
      `${loanAPI}/user/${userId}?skip=${skip}&limit=${limit}`
    )
    return response.data
  }

  return useQuery({
    queryKey: loanKeys.personal({ userId, skip, limit }),
    queryFn: fetchPersonalLoans,
    enabled: !!userId,
  })
}

export function usePostInstalment() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function postInstalment(instalment: IPostInstalmentRequest) {
    const response = await axiosPrivate.post<IPostInstalmentResponse>(
      `${instalmentAPI}`,
      {
        ...instalment,
      }
    )
    return response.data
  }

  return useMutation<IPostInstalmentResponse, Error, IPostInstalmentRequest>({
    mutationFn: postInstalment,
    onSuccess: () =>
      queryClient.removeQueries({ queryKey: instalmentKeys.all({}) }),
  })
}

export function useFetchLoansByDate({
  tenantId,
  startDate,
  endDate,
}: {
  tenantId: string
  startDate?: string
  endDate?: string
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchLoansByDate() {
    const response = await axiosPrivate.get<ILoansByDate[]>(
      `${loanAPI}/tenant/${tenantId}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  }

  return useQuery({
    queryKey: loanKeys.loanDates(tenantId),
    queryFn: fetchLoansByDate,
    enabled: !!tenantId,
  })
}

export function useFetchInstalmentsByDate({
  tenantId,
  startDate,
  endDate,
}: {
  tenantId: string
  startDate?: string
  endDate?: string
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInstalmentsByDate() {
    const response = await axiosPrivate.get<IInstalmentsByDate[]>(
      `${instalmentAPI}/tenant/${tenantId}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  }

  return useQuery({
    queryKey: instalmentKeys.instalmentDates(tenantId),
    queryFn: fetchInstalmentsByDate,
    enabled: !!tenantId,
  })
}

export function useFetchInterestDue({
  loanId,
  options,
}: {
  loanId: string
  options: Partial<UseQueryOptions<{ interest: number }>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchIInterestDue() {
    const response = await axiosPrivate.get<{ interest: number }>(
      `${loanAPI}/interest/${loanId}`
    )
    return response.data
  }

  return useQuery({
    queryKey: instalmentKeys.interestDue(loanId),
    queryFn: fetchIInterestDue,
    ...options,
  })
}
