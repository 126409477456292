import { ReactNode, Suspense } from "react"
import { BrowserRouter } from "react-router-dom"

import { ThemeProvider } from "@mui/material"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { AuthProvider } from "src/contexts/AuthProvider"
import { AppRouter } from "src/router/AppRouter"
import { theme } from "src/theme"
import { GlobalStyle } from "src/ui/globalStyles"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"

const queryClient = new QueryClient()

function ProviderWrapper({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <GlobalStyle />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        <ProviderWrapper>
          <AppRouter />
        </ProviderWrapper>
      </BrowserRouter>
    </Suspense>
  )
}
