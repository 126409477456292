import styled, { css } from "styled-components/macro"

import { paletteColor } from "src/ui/colors"

const fontWeight = {
  light: 300,
  regular: 400,
  regularBold: 500,
  semiBold: 600,
  bold: 700,
}

const TypographyHeading1 = css`
  font-size: 3.5rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.375;
`

export const Heading1 = styled.div`
  ${TypographyHeading1};
`

const TypographyHeading2 = css`
  font-size: 3rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.375rem;
`

export const Heading2 = styled.div`
  ${TypographyHeading2};
`

const TypographyHeading3 = css`
  font-size: 2.25rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.375rem;
`

export const Heading3 = styled.div`
  ${TypographyHeading3};
`

export const TypographyHeading4 = css`
  font-size: 1.5rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.375rem;
`

export const Heading4 = styled.div`
  ${TypographyHeading4};
`

export const TypographyHeading5 = css`
  font-size: 1.25rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.375rem;
`

export const Heading5 = styled.div`
  ${TypographyHeading5};
`

export const TypographyBody1 = css`
  font-size: 1rem;
  font-weight: ${fontWeight.regularBold};
  line-height: 1.5rem;
`

export const Body1 = styled.div`
  ${TypographyBody1};
`

export const Body = styled.div`
  color: ${paletteColor.secondary.main};
  ${TypographyBody1};
`

export const TypographyBody2 = css`
  font-size: 0.875rem;
  font-weight: ${fontWeight.regularBold};
  line-height: 1.375rem;
`

export const Body2 = styled.div`
  ${TypographyBody2};
`

export const TypographySubtitle1 = css`
  font-size: 1rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.5rem;
`

export const Subtitle1 = styled.div`
  ${TypographySubtitle1};
`

export const TypographySubtitle2 = css`
  font-size: 0.875rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.375rem;
`

export const Subtitle2 = styled.div`
  ${TypographySubtitle2};
`

export const TypographyCaption = css`
  font-size: 0.75rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.125rem;
`

export const Caption = styled.div`
  color: ${paletteColor.secondary.main};
  ${TypographyCaption};
`

export const TypographyCaption1 = css`
  font-size: 0.75rem;
  font-weight: ${fontWeight.regular};
  line-height: 1.125rem;
`

export const Caption1 = styled.div`
  ${TypographyCaption1};
`

export const TypographyCaption2 = css`
  font-size: 0.75rem;
  font-weight: ${fontWeight.regular};
  line-height: 1.125rem;
`

export const Caption2 = styled.div`
  color: ${paletteColor.secondary.main};
  ${TypographyCaption2};
`
