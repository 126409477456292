import { Link, useNavigate } from "react-router-dom"

import { useFetchTenants } from "src/data/tenants/tenantQueries"
import { AppRoutes } from "src/router/AppRoutes"
import { MButton } from "src/ui/Button/MButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { Caption } from "src/ui/typography"

export function Tenants() {
  const navigate = useNavigate()
  const fetchTenants = useFetchTenants()
  const tenants = fetchTenants.data?.tenants || []

  const noTenants =
    !fetchTenants.isPending && fetchTenants.isFetched && !tenants.length

  const headerData = ["Owner", "Name", "Company code", "Subscription plan"]
  const header = headerData.map((item) => <div>{item}</div>)

  const rows = tenants.map((tenant) => {
    return (
      <>
        <div>
          {tenant.owner}
          <Caption>{tenant.email}</Caption>
        </div>
        <div>{tenant.companyName}</div>
        <Link to={AppRoutes.Tenant.path(tenant.code)}>{tenant.code}</Link>
        <div>{tenant.subscriptionPlan}</div>
      </>
    )
  })

  return (
    <main>
      <PageTitle pageLabel="Tenants">
        <MButton onClick={() => navigate(AppRoutes.CreateTenant)}>
          Create new tenant
        </MButton>
      </PageTitle>

      {noTenants ? (
        <div>No tenants found ...</div>
      ) : (
        <GridTable
          header={header}
          rows={rows}
          templateColumns={`max-content repeat( 3, 1fr)`}
          loading={fetchTenants.isPending}
        />
      )}
    </main>
  )
}
