import styled from "styled-components/macro"

import { IconButton, useMediaQuery } from "@mui/material"

import { NotificationCenter } from "src/components/AppLayout/NotificationCenter"
import { ProfileButton } from "src/components/AppLayout/ProfileButton"
import { breakpoints } from "src/data/constants/breakpoints"
import { paletteColor } from "src/ui/colors"
import { ReactComponent as MenuOutlinedIcon } from "src/ui/icons/MenuOutlined.svg"
import { spacing } from "src/ui/spacing"

export function AppHeader({
  openDrawer,
  setOpenDrawer,
}: {
  openDrawer: boolean
  setOpenDrawer: (value: boolean) => void
}) {
  const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.S})`, {
    noSsr: true,
  })

  return (
    <Box>
      <div>
        {isMobileScreen && (
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </div>
      <IconsBox>
        <NotificationCenter />
        <ProfileButton />
      </IconsBox>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  padding: ${spacing.XS} ${spacing.M};
  height: 30px;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid ${paletteColor.divider};
  z-index: 10;
`

const IconsBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  justify-content: space-between;
  flexshrink: 0;
`
