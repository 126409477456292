import styled from "styled-components/macro"

import { paletteColor } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export type TColor = "primary" | "secondary" | "success" | "error" | "warning"
export type TChipVariant = "outlined" | "filled"

export function MChip({
  label,
  color = "primary",
  variant = "filled",
}: {
  label: string
  color: TColor
  variant?: TChipVariant
}) {
  return (
    <Box color={getColor(color)} variant={variant}>
      {label}
    </Box>
  )
}

function getColor(color: TColor) {
  if (color === "secondary") return paletteColor.secondary.main
  if (color === "success") return paletteColor.success.main
  if (color === "error") return paletteColor.error.main
  if (color === "warning") return paletteColor.warning.main
  return paletteColor.primary.main
}

const Box = styled.div<{ color: string; variant: TChipVariant }>`
  white-space: nowrap;
  text-align: center;
  padding: 0 ${spacing.XS};
  background-color: ${({ color, variant }) =>
    variant === "filled" ? color : "none"};
  color: ${({ color, variant }) => (variant === "filled" ? "#FFF" : color)};
  border-radius: 4px;
  width: min-content;
  box-sizing: border-box;
  border: 1px solid
    ${({ color, variant }) => (variant === "filled" ? "unset" : color)};
`
