import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { UserRole } from "src/data/roles/roles"
import { userKeys } from "src/data/users/userQueriesCache"
import { ICreateUser, IFetchUsers, IUser } from "src/data/users/userTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

const usersAPI = "/user"

export function useFetchUser({
  userId,
  options,
}: {
  userId?: string
  options?: UseQueryOptions<IUser>
}) {
  const useAxios = useAxiosHttp()
  async function fetchUser() {
    const response = await useAxios.get<IUser>(`${usersAPI}/${userId}`)
    return response.data
  }
  return useQuery({
    queryKey: userKeys.user(userId ?? ""),
    queryFn: fetchUser,
    ...options,
  })
}

export function useFetchUsers({
  skip,
  limit,
  userIds,
  options,
}: {
  skip?: number
  limit?: number
  userIds?: string[]
  options?: Partial<
    UseQueryOptions<
      IFetchUsers,
      AxiosError,
      IFetchUsers,
      ReturnType<typeof userKeys.userList>
    >
  >
}) {
  const useAxios = useAxiosHttp()
  async function fetchUsers() {
    const response = await useAxios.get<IFetchUsers>(`${usersAPI}`, {
      params: {
        userIds,
        limit,
        skip,
      },
    })
    return response.data
  }

  return useQuery({
    queryKey: userKeys.userList({ skip, limit }),
    queryFn: fetchUsers,
    ...options,
  })
}

export function usePatchUser() {
  const axiosHttp = useAxiosHttp()
  const queryClient = useQueryClient()

  async function patchUser({
    userId,
    name,
    role,
  }: {
    userId: string
    name?: string
    role?: UserRole[]
  }) {
    const response = await axiosHttp.patch<IUser>(`${usersAPI}/${userId}`, {
      name,
      role,
    })
    return response.data
  }
  return useMutation<
    IUser,
    Error,
    { userId: string; name: string; role: UserRole[] }
  >({
    mutationFn: patchUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.all() })
    },
  })
}

export function usePostUser() {
  const axiosHttp = useAxiosHttp()
  const queryClient = useQueryClient()
  async function postUser({
    newUser,
    adminId,
  }: {
    newUser: ICreateUser
    adminId?: string
  }) {
    const response = await axiosHttp.post<IUser>(`${usersAPI}`, {
      ...newUser,
    })
    return response.data
  }

  return useMutation<IUser, Error, { newUser: ICreateUser; adminId?: string }>({
    mutationFn: postUser,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: userKeys.userList({}),
      }),
  })
}

export function useDeleteUser() {
  const axiosHttp = useAxiosHttp()
  const queryClient = useQueryClient()

  async function deleteUser(userId: string) {
    const response = await axiosHttp.delete<{ userId: string }>(
      `${usersAPI}/${userId}`
    )
    return response.data
  }

  return useMutation<{ userId: string }, Error, string>({
    mutationFn: deleteUser,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: userKeys.userList({}) }),
  })
}

export function usePostRegistrationToken() {
  const axiosHttp = useAxiosHttp()
  //const queryClient = useQueryClient()

  async function postRegistrationToken({
    registrationToken,
  }: {
    registrationToken: string
  }) {
    const response = await axiosHttp.post(`${usersAPI}/token`, {
      registrationToken,
    })
    return response.data
  }

  return useMutation({ mutationFn: postRegistrationToken })
}
