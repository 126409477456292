export const notificationKeys = {
  all() {
    return ["notifications"]
  },

  notifications(filters?: {
    skip?: number
    limit?: number
    userId?: string
    isOpen?: boolean
    restrictToUser?: boolean
  }) {
    return [
      ...this.all(),
      `skip=${filters?.skip}`,
      `limit=${filters?.limit}`,
      `userId=${filters?.userId}`,
      `isOpen=${filters?.isOpen}`,
      `restrictToUser=${filters?.restrictToUser}`,
    ]
  },

  notification(userId: string) {
    return [...this.all(), userId]
  },
} as const
