import Avatar from "@mui/material/Avatar"
import createColor from "create-color"

import { Body2, Caption2, Heading5 } from "src/ui/typography"

export function MAvatar({
  name,
  avatarSize,
  textSize = "none",
}: {
  name: string
  avatarSize?: number
  textSize?: "none" | "small" | "medium" | "large"
}) {
  const acronym = name.match(/\b\w/g)?.join("").substring(0, 2) || ""

  function FontText() {
    switch (textSize) {
      case "small":
        return <Caption2>{acronym}</Caption2>

      case "medium":
        return <Body2>{acronym}</Body2>

      case "large":
        return <Heading5>{acronym}</Heading5>

      default:
        return <>{acronym}</>
    }
  }

  return (
    <div>
      <Avatar
        sx={{
          bgcolor: createColor([name]),
          width: avatarSize,
          height: avatarSize,
        }}
      >
        <FontText />
      </Avatar>
    </div>
  )
}
