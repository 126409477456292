import { useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import styled from "styled-components/macro"

import { TextField, useMediaQuery } from "@mui/material"

import { MCard } from "src/components/Cards/MCard"
import { breakpoints } from "src/data/constants/breakpoints"
import { usePatchOrganisation } from "src/data/organisation/organisationQueries"
import { IOrganisation } from "src/data/organisation/organisationTypes"
import { IUser } from "src/data/users/userTypes"
import { spacing } from "src/ui/spacing"
import { TypographyBody2 } from "src/ui/typography"

export function TenantSetup({
  user,
  organisation,
}: {
  organisation: IOrganisation | undefined
  user: IUser | null
}) {
  const patchOrganisation = usePatchOrganisation()

  const [isDisabled, setIsDisabled] = useState(true)
  const [orgName, setOrgName] = useState(organisation?.name)

  const [annualFee, setAnnualFee] = useState<NumberFormatValues>({
    floatValue: organisation?.annualFee,
    formattedValue: organisation?.annualFee?.toString() ?? "",
    value: organisation?.annualFee?.toString() ?? "",
  })

  const [interestRate, setInterestRate] = useState<NumberFormatValues>({
    floatValue: organisation?.interestRate,
    formattedValue: organisation?.interestRate?.toString() ?? "",
    value: organisation?.interestRate?.toString() ?? "",
  })

  const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.S})`, {
    noSsr: true,
  })

  function handleUpdate() {
    setIsDisabled(!isDisabled)
    !isDisabled &&
      patchOrganisation.mutate({
        organisationId: user?.organisationId ?? "",
        interestRate: interestRate.floatValue,
        annualFee: annualFee.floatValue,
        name: orgName || "",
      })
  }

  return (
    <Box>
      <MCard
        title={`${organisation?.name || "Unknown organisation"} details`}
        onConfirm={handleUpdate}
        loading={patchOrganisation.isPending}
        confirmLabel={isDisabled ? "Edit" : "Update"}
        onCancel={() => setIsDisabled(!isDisabled)}
        cancelButtonProps={{ disabled: isDisabled }}
      >
        {!!organisation && (
          <FormBox isMobileScreen={isMobileScreen}>
            <div>
              <StyledBody>Name</StyledBody>
              <TextField
                size="small"
                defaultValue={organisation.name}
                onChange={(e) => setOrgName(e.target.value)}
                fullWidth
                disabled={isDisabled}
              />
            </div>

            <div>
              <StyledBody>Owner</StyledBody>
              <TextField
                size="small"
                defaultValue={organisation.owner}
                disabled
                fullWidth
              />
            </div>

            <div>
              <StyledBody> Annual fee</StyledBody>
              <NumericFormat
                size="small"
                label=" Annual fee"
                value={organisation.annualFee}
                defaultValue=""
                decimalScale={4}
                thousandSeparator=","
                customInput={TextField}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(value) => setAnnualFee(value)}
                required
                disabled={isDisabled}
                fullWidth
              />
            </div>

            <div>
              <StyledBody> Interest rate</StyledBody>
              <NumericFormat
                size="small"
                label="Interest rate"
                value={organisation.interestRate}
                defaultValue=""
                decimalScale={4}
                thousandSeparator=","
                customInput={TextField}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(values) => setInterestRate(values)}
                required
                disabled={isDisabled}
                fullWidth
              />
            </div>
          </FormBox>
        )}
      </MCard>
    </Box>
  )
}

const StyledBody = styled.div`
  margin-bottom: ${spacing.S};
  ${TypographyBody2};
`

const FormBox = styled.form<{ isMobileScreen?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isMobileScreen ? "1fr" : "1fr 1fr"};
  gap: ${spacing.M};
  align-items: center;
`

const Box = styled.div`
  max-width: 55ch;
`
