import { useState } from "react"

import { MChip } from "src/components/MChip/MChip"
import { MPagination } from "src/components/Pagination/MPagination"
import { MTable } from "src/components/Table/MTable"
import { PayMembership } from "src/components/Users/PayMembership"
import { useAuth } from "src/contexts/AuthProvider"
import { MAX_LIMIT } from "src/data/constants/constants"
import { useFetchAnnualFee } from "src/data/memberships/membershipQueries"
import { useFetchUsers } from "src/data/users/userQueries"
import { MButton } from "src/ui/Button/MButton"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { statusToColorProfile } from "src/utils/utils"

export function MembershipList() {
  const limit = MAX_LIMIT
  const { auth } = useAuth()
  const [open, setOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const currentYear = new Date().getFullYear()

  const fetchUsers = useFetchUsers({
    skip: offset,
    limit,
  })
  const users = fetchUsers.data?.users || []
  const userIds = users.map((user) => user.userId)

  const fetchAnnuaFee = useFetchAnnualFee({
    skip: offset,
    limit: limit,
    year: currentYear,
    userIds,
    reviewStatus: "Approved",
    options: { enabled: users.length > 0 },
  })
  const annualFee = fetchAnnuaFee.data?.annualFee || []

  const fetchCurrentUserAnnualFee = useFetchAnnualFee({
    skip: offset,
    limit: limit,
    year: currentYear,
    userIds: !!auth?.user?.userId ? [auth?.user?.userId] : [],
    options: { enabled: !!auth?.user?.userId },
  })

  const userAnnualFee = fetchCurrentUserAnnualFee.data?.annualFee.find(
    (fee) => fee.userId === auth?.user?.userId
  )

  const headerData = ["Name", "Amount Paid (UGX)", "Year", "Status"]
  const header = headerData.map((item) => <div>{item}</div>)
  const rows = users.map((user) => {
    const userAnnualFee = annualFee.find((fee) => fee.userId === user.userId)

    return (
      <>
        <div>{user.name}</div>
        <div>{userAnnualFee?.amount?.toLocaleString("en-GB") ?? 0}</div>
        <div>{userAnnualFee?.year ?? new Date().getFullYear()}</div>
        <div>
          <MChip
            label={userAnnualFee?.reviewStatus ?? "Not paid"}
            color={statusToColorProfile(userAnnualFee?.reviewStatus ?? "Error")}
          />
        </div>
      </>
    )
  })

  if (
    fetchAnnuaFee.isPending ||
    fetchUsers.isLoading ||
    fetchCurrentUserAnnualFee.isLoading
  ) {
    return <LoadingScreen />
  }

  return (
    <div>
      <MTable
        title="Administration fee status"
        header={header}
        rows={rows}
        templateColumns={`repeat( 4, minmax(min-content, 1fr))`}
        action={
          <MButton
            onClick={() => setOpen(true)}
            size="small"
            disabled={!!userAnnualFee}
          >
            Pay administration fee
          </MButton>
        }
        pagination={
          <MPagination
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchAnnuaFee.data?.totalCount || 0}
          />
        }
      />

      <PayMembership open={open} setOpen={setOpen} />
    </div>
  )
}
