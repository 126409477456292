import { TReviewStatus } from "src/data/constants/globalTypes"

export const investmentKeys = {
  all() {
    return ["investment"]
  },

  investments(filters?: {
    skip?: number
    limit?: number
    userId?: string
    reviewStatus?: TReviewStatus
    startDate?: string
    endDate?: string
  }) {
    return [...this.all(), `skip=${filters?.skip}`, `limit=${filters?.limit}`]
  },

  investment(userId: string) {
    return [...this.investments({}), userId]
  },

  investmentDate(tenantId: string) {
    return [...this.investments({}), tenantId]
  },

  investmentSummary(filters?: {
    userId?: string
    reviewStatus?: TReviewStatus
  }) {
    return [
      ...this.all(),
      `userId=${filters?.userId}`,
      `reviewStatus=${filters?.reviewStatus}`,
    ]
  },
} as const
