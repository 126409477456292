import { useState } from "react"
import styled from "styled-components/macro"

import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material"

import { MPagination } from "src/components/Pagination/MPagination"
import { MTable } from "src/components/Table/MTable"
import { CreateUser } from "src/components/Users/CreateUser"
import { UpdateUser } from "src/components/Users/UpdateUser"
import { useAuth } from "src/contexts/AuthProvider"
import { MAX_LIMIT } from "src/data/constants/constants"
import { UserRole } from "src/data/roles/roles"
import { useDeleteUser, useFetchUsers } from "src/data/users/userQueries"
import { IUser } from "src/data/users/userTypes"
import { MButton } from "src/ui/Button/MButton"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ReactComponent as AddOutlinedIcon } from "src/ui/icons/Add.svg"
import { ReactComponent as DeleteIcon } from "src/ui/icons/Delete.svg"
import { ReactComponent as EditIcon } from "src/ui/icons/Edit.svg"
import { ReactComponent as MoreHorizIcon } from "src/ui/icons/MoreHoriz.svg"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { spacing } from "src/ui/spacing"
import { Body2, Caption2 } from "src/ui/typography"

export function Users() {
  const { auth } = useAuth()
  const limit = MAX_LIMIT
  const [open, setOpen] = useState(false)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [offset, setOffset] = useState(0)
  const popoverId = !!anchorEl ? "user-setting" : undefined
  const isAdmin =
    (auth?.user?.role.includes(UserRole.ADMIN) ||
      auth?.user?.role.includes(UserRole.SUPER)) ??
    false
  const fetchUsers = useFetchUsers({
    skip: offset,
    limit,
  })

  const deleteUser = useDeleteUser()

  const headerData = ["User", "Role", "Verified", "Status", ""]
  const header2 = headerData.map((item) => <div>{item}</div>)
  !isAdmin && header2.pop()

  const header = [
    <div>User</div>,
    <div>Verified</div>,
    <div>Status</div>,
    <div></div>,
  ]

  const users: IUser[] = fetchUsers.data?.users ?? []
  const totalCount = fetchUsers.data?.totalCount ?? 0

  const rows = users.map((user) => {
    return (
      <>
        <div key={user.userId}>
          <Body2>{user.name}</Body2>
          <Caption2> {user.email}</Caption2>
        </div>
        <div>{user ? "Yes" : "No"}</div>
        <div>{!!user ? "Active" : "Inactive"}</div>
        <div>
          {isAdmin && (
            <ButtonIconBox>
              <StyledIconButton
                aria-label="settings"
                aria-describedby={popoverId}
                onClick={(e) => {
                  setSelectedUser(user)
                  handleButtonClick(e.currentTarget)
                }}
              >
                <MoreHorizIcon />
              </StyledIconButton>
            </ButtonIconBox>
          )}
        </div>
      </>
    )
  })

  function handleButtonClick(event: HTMLButtonElement) {
    setAnchorEl(event)
  }

  function handlePopoverClose() {
    setAnchorEl(null)
  }

  function handleDeleteUser() {
    if (!!selectedUser) {
      return deleteUser.mutate(selectedUser.userId, {
        onSuccess: () => setOpen(false),
      })
    }
  }

  if (fetchUsers.isPending) {
    return <LoadingScreen />
  }

  return (
    <Box>
      <PageTitle pageLabel="Users" />
      <MTable
        title="Active"
        header={header}
        rows={rows}
        templateColumns="1fr 1fr 1fr 1fr"
        action={
          isAdmin && (
            <MButton
              onClick={() => setOpenCreateDialog(true)}
              size="small"
              startIcon={<AddOutlinedIcon />}
            >
              Add user
            </MButton>
          )
        }
        pagination={
          <MPagination
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={totalCount}
          />
        }
      />

      <Popover
        id={popoverId}
        open={!!anchorEl}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListItemButton
          onClick={() => {
            setAnchorEl(null)
            !!selectedUser && setOpenUpdateDialog(true)
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit user" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            setAnchorEl(null)
            setOpen(true)
          }}
          disabled={auth?.user?.userId === selectedUser?.userId}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete user" />
        </ListItemButton>
      </Popover>

      <MDialog
        open={open}
        onClose={() => setOpen(false)}
        title={`Delete ${selectedUser?.name}`}
        body={`You are about to delete the user ${selectedUser?.name}. This action is irreversable`}
        confirmLabel="Delete user"
        loading={deleteUser.isPending}
        onConfirm={handleDeleteUser}
        errorText={
          deleteUser.isError ? `Failed to delete ${selectedUser?.name}` : ""
        }
      />

      {!!selectedUser && (
        <UpdateUser
          key={selectedUser.userId}
          open={openUpdateDialog}
          setOpen={setOpenUpdateDialog}
          user={selectedUser}
        />
      )}

      <CreateUser open={openCreateDialog} setOpen={setOpenCreateDialog} />
    </Box>
  )
}

const Box = styled.div`
  width: max-content;
`

const ButtonIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledIconButton = styled(IconButton)`
  padding: ${spacing.XS};
  margin: ${spacing.XL};
`
