import styled from "styled-components/macro"

import { ReactComponent as Logo } from "src/ui/icons/logo.svg"

export function LoginBackground() {
  return (
    <Box>
      <Logo width="100%" height="calc(100vh - 80px)" viewBox="0 0 500 900" />
    </Box>
  )
}

const Box = styled.div`
  position: absolute;
  filter: blur(16px);
  z-index: -1;
  bottom: 0;
  background-color: #fafafb;
`
