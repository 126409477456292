import styled from "styled-components/macro"

import { Divider } from "@mui/material"

import { ITenant } from "src/data/tenants/tenantTypes"
import { MButton } from "src/ui/Button/MButton"
import { colors } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { Subtitle1, TypographyBody2 } from "src/ui/typography"

export function TenantDetailCard({
  tenant,
  update,
  setOpen,
}: {
  tenant: ITenant
  update: () => void
  setOpen: (value: boolean) => void
}) {
  return (
    <CardBox>
      <Subtitle1>{tenant.companyName}</Subtitle1>
      <Divider />
      <div>
        <Details>
          <div>Email: </div> <div>{tenant?.email}</div>
        </Details>
        <Details>
          <div>Owner: </div> <div>{tenant?.owner}</div>
        </Details>
        <Details>
          <div>Code: </div> <div>{tenant?.code}</div>
        </Details>
        <Details>
          <div>Subscription plan: </div> <div>{tenant?.subscriptionPlan}</div>
        </Details>
        <Details>
          <div>Status: </div>
          <div>{tenant?.isDisabled ? "Disabled" : "Enabled"}</div>
        </Details>
      </div>

      <Divider />

      <ActionButtons>
        <MButton onClick={() => update()}>
          {tenant.isDisabled ? "Enable" : "Disable"}
        </MButton>
        <MButton
          color="error"
          disabled={!tenant.isDisabled}
          onClick={() => setOpen(true)}
        >
          Delete
        </MButton>
      </ActionButtons>
    </CardBox>
  )
}

const CardBox = styled.div`
  display: grid;
  gap: ${spacing.L};
  border: 1px solid ${colors.divider};
  width: fit-content;
  padding: ${spacing.L};
  border-radius: 12px;
`

const ActionButtons = styled.div`
  display: flex;
  gap: ${spacing.L};
`

const Details = styled.div`
  display: flex;
  gap: ${spacing.L};
  ${TypographyBody2};
`
