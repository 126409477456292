import { useEffect } from "react"

import { AxiosError, InternalAxiosRequestConfig } from "axios"

import { baseHttpClient } from "src/api/httpClient"
import { useAuth } from "src/contexts/AuthProvider"
import { useRefreshToken } from "src/hooks/useRefreshToken"

export function useAxiosHttp() {
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    const requestInterceptor = baseHttpClient.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (!config.headers?.Authorization && auth?.accessToken) {
          //config.headers = config.headers ?? {}
          config.headers.Authorization = `Bearer ${auth?.accessToken}`
        }

        return config
      },
      (error: AxiosError) => Promise.reject(error)
    )

    const responseInterceptor = baseHttpClient.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const prevRequest = error?.config as InternalAxiosRequestConfig & {
          sent?: boolean
        }
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true

          try {
            const newAccessToken = await refresh()
            if (newAccessToken) {
              prevRequest.headers.Authorization = `Bearer ${newAccessToken}`

              return baseHttpClient(prevRequest)
            }
          } catch (error) {
            console.error("Error refreshing token:", error)
            return Promise.reject(error)
          }
        }
        return Promise.reject(error)
      }
    )
    return () => {
      baseHttpClient.interceptors.request.eject(requestInterceptor)
      baseHttpClient.interceptors.response.eject(responseInterceptor)
    }
  }, [auth, refresh])

  return baseHttpClient
}
