import { TReviewStatus } from "src/data/constants/globalTypes"
import { cleanObjectConvertToMap } from "src/utils/utils"

export const instalmentKeys = {
  all() {
    return ["instalment"]
  },

  instalments(filters?: {
    skip?: number
    limit?: number
    userId?: string
    reviewStatus?: TReviewStatus
    startDate?: string
    endDate?: string
  }) {
    if (!filters) {
      return this.all()
    }

    return [...this.all(), cleanObjectConvertToMap(filters)] as const
  },

  instalmentSummary(filters?: {
    userId?: string
    reviewStatus?: TReviewStatus
    isInterestPaid?: boolean
  }) {
    if (!filters) {
      return this.all()
    }

    return [...this.all(), cleanObjectConvertToMap(filters)] as const
  },
} as const
