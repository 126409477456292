import { useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import {
  Alert,
  ClickAwayListener,
  Divider,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material"

// import * as Sentry from "@sentry/react"
import { MAvatar } from "src/components/Avatar/MAvatar"
import { useAuth } from "src/contexts/AuthProvider"
import { AppRoutes } from "src/router/AppRoutes"
import { MButton } from "src/ui/Button/MButton"
import { paletteColor } from "src/ui/colors"
import { ReactComponent as LogoutOutlined } from "src/ui/icons/Logout.svg"
import { ReactComponent as PersonOutlineOutlinedIcon } from "src/ui/icons/PersonOutline.svg"
import { spacing } from "src/ui/spacing"
import { Body2 } from "src/ui/typography"

export function ProfileButton() {
  const { auth, logout } = useAuth()
  const user = auth?.user
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)

  const handleClose = () => {
    return setOpen(false)
  }

  function handleProfileClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  async function handleLogOutClick() {
    setOpen(false)
    try {
      await logout()
      handleClose()
    } catch (error) {
      setError(true)
      // Sentry.captureException(error)
    }
  }

  return (
    <div>
      <MButton
        size="small"
        variant="text"
        color="info"
        onClick={(e) => handleProfileClick(e)}
      >
        <IconBox>
          <MAvatar avatarSize={24} textSize="medium" name={user?.name || ""} />
        </IconBox>
        <Body2> {user?.name}</Body2>
      </MButton>

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <List component="nav">
                    <ListItemButton>
                      <ListItemIcon>
                        <MAvatar
                          avatarSize={24}
                          textSize="small"
                          name={user?.name || ""}
                        />
                      </ListItemIcon>
                      <ListItemText primary={user?.name} />
                    </ListItemButton>

                    <Divider />

                    <ListItemButton
                      onClick={(e) => {
                        navigate(AppRoutes.ProfileRoutes.userProfile)
                        handleClose()
                      }}
                    >
                      <ListItemIcon>
                        <PersonOutlineOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="View Profile" />
                    </ListItemButton>

                    <ListItemButton onClick={handleLogOutClick}>
                      <ListItemIcon>
                        <LogoutOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                    {error && <Alert severity="error">Failed to log out</Alert>}
                  </List>
                </div>
              </ClickAwayListener>
            </StyledPaper>
          </Fade>
        )}
      </StyledPopper>
    </div>
  )
}

const IconBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing.XS};
`

const StyledPaper = styled(Paper)`
  border: 1px solid ${paletteColor.secondary.light};
  width: 290px;
  min-width: 240px;
  max-width: 290px;
`

const StyledPopper = styled(Popper)`
  z-index: 10;
`
