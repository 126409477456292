import { useState } from "react"
import styled from "styled-components/macro"

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import { UserRole } from "src/data/roles/roles"
import { usePostUser } from "src/data/users/userQueries"
import { ICreateUser } from "src/data/users/userTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"
import { trimString } from "src/utils/utils"

export function CreateUser({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (value: boolean) => void
}) {
  const { auth } = useAuth()

  const [user, setUser] = useState<ICreateUser>({
    name: "",
    email: "",
    role: [],
    password: "",
  })
  const postUser = usePostUser()
  const createUserFormId = "create-user-id"

  const userRoles = Object.values(UserRole)

  async function handleSubmit() {
    try {
      const userData: ICreateUser = {
        name: trimString(user.name),
        email: trimString(user.email),
        role: user.role,
        password: user.password,
      }
      postUser.mutate(
        { newUser: userData, adminId: auth?.user?.userId },
        {
          onSuccess: () => {
            setOpen(false)
            setUser({
              name: "",
              email: "",
              role: [],
              password: "",
            })
          },
        }
      )
    } catch {
      console.log("Failed to create user")
    }
  }

  const handleRoleSelectChange = (event: SelectChangeEvent<UserRole[]>) => {
    const value = event.target.value as UserRole[]

    setUser((prev) => ({ ...prev, role: value }))
  }

  return (
    <MDialog
      title="Create a new user"
      open={open}
      onClose={() => setOpen(false)}
      confirmLabel="Create user"
      onConfirm={handleSubmit}
      formId={createUserFormId}
      loading={postUser.isPending}
      errorText={
        postUser.isError ? "There was a problen creating the user" : ""
      }
    >
      <FormBox id={createUserFormId}>
        <TextField
          label="Full name"
          value={user.name}
          onChange={(e) =>
            setUser((prev) => ({ ...prev, name: e.target.value }))
          }
          required
        />
        <TextField
          label="Email"
          value={user.email}
          type="email"
          onChange={(e) =>
            setUser((prev) => ({ ...prev, email: e.target.value }))
          }
          required
        />

        <FormControl>
          <InputLabel id="multiple-roles-label">User role</InputLabel>
          <Select
            multiple
            value={user.role}
            onChange={handleRoleSelectChange}
            input={<OutlinedInput id="select-roles-chip" label="User roles" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {userRoles.map((role) => (
              <MenuItem key={role} value={role}>
                <Checkbox size="small" checked={user.role.indexOf(role) > -1} />
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Password"
          value={user.password}
          type="password"
          onChange={(e) =>
            setUser((prev) => ({ ...prev, password: e.target.value }))
          }
          required
          autoComplete="new-password"
        />
      </FormBox>
    </MDialog>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
`
