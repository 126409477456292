import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { useAuth } from "src/contexts/AuthProvider"
import { useRefreshToken } from "src/hooks/useRefreshToken"
import { AppRoutes } from "src/router/AppRoutes"

export function PersistLogin() {
  const { auth } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    //setIsLoading(true)
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        navigate(AppRoutes.Login.path())
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    if (!auth?.accessToken) {
      verifyRefreshToken()
    } else {
      setIsLoading(false)
    }

    // Clean up
    return () => {
      isMounted = false
      setIsLoading(false)
    }
  }, [auth, navigate, refresh])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <Outlet />
}
