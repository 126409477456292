import { ReactNode } from "react"
import styled from "styled-components/macro"

import { LoginBackground } from "src/components/Login/LoginBackground"
import { ReactComponent as Logo } from "src/ui/icons/logo.svg"
import { spacing } from "src/ui/spacing"
import { TypographyHeading5 } from "src/ui/typography"

export function LoginWrapper({ children }: { children: ReactNode }) {
  return (
    <Box>
      <LoginBackground />
      <GridBox>
        <LogoBox>
          <Logo width="40px" height="40px" />
          Group Finance
        </LogoBox>
        <Body>{children}</Body>
      </GridBox>
    </Box>
  )
}

const Box = styled.div`
  min-height: 100vh;
`

const GridBox = styled.div`
  min-height: 100vh;
`

const Body = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
`

const LogoBox = styled.div`
  display: flex;
  padding: ${spacing.S} 0 0 ${spacing.S};
  align-items: center;
  ${TypographyHeading5};
`
