import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"

import { organisationKeys } from "src/data/organisation/organisationKeys"
import {
  IOrganisation,
  IPostUpdateOrganisation,
} from "src/data/organisation/organisationTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

const organisationAPI = "/organisation"

export function useFetchOrganisation({
  organisationId,
  options,
}: {
  organisationId: string
  options?: Partial<UseQueryOptions<IOrganisation>>
}) {
  const axiosPrivate = useAxiosHttp()
  async function fetchOrganisation(): Promise<IOrganisation> {
    const response = await axiosPrivate.get<IOrganisation>(
      `${organisationAPI}/${organisationId}`
    )
    return response.data
  }

  return useQuery({
    queryKey: organisationKeys.organisation(organisationId),
    queryFn: fetchOrganisation,
    ...options,
  })
}

export function usePatchOrganisation() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function patchOrganisation({
    organisationId,
    name,
    interestRate,
    annualFee,
  }: IPostUpdateOrganisation) {
    const response = await axiosPrivate.patch<IOrganisation>(
      `${organisationAPI}/${organisationId}`,
      {
        name,
        interestRate,
        annualFee,
      }
    )
    return response.data
  }

  return useMutation({
    mutationFn: patchOrganisation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: organisationKeys.all() })
    },
  })
}
