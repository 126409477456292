import { useNavigate } from "react-router-dom"

import { baseHttpClient } from "src/api/httpClient"
import { IAuthUser, useAuth } from "src/contexts/AuthProvider"
import { authAPI } from "src/data/auth/authQueries"
import { AppRoutes } from "src/router/AppRoutes"

export function useRefreshToken() {
  const { setAuth } = useAuth()
  const navigate = useNavigate()

  async function refresh() {
    try {
      const response = await baseHttpClient.post<IAuthUser>(`${authAPI}/token`)

      setAuth(response.data)

      return response.data.accessToken
    } catch (error) {
      console.error("Failed to refresh token:", error)
      navigate(AppRoutes.Login.path())
    }
  }

  return refresh
}
