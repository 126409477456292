import { PageTitle } from "src/ui/PageTitle/PageTitle"

export function NotFound() {
  return (
    <div>
      <PageTitle pageLabel="Page not found"></PageTitle>
      <div>404: Page Not Found</div>
    </div>
  )
}
