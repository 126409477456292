import { ReactNode } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components/macro"

import { useMediaQuery } from "@mui/material"

import { useAuth } from "src/contexts/AuthProvider"
import { breakpoints } from "src/data/constants/breakpoints"
import { useFetchOrganisation } from "src/data/organisation/organisationQueries"
import { ADMINS, hasRequiredRoles, SUPER, UserRole } from "src/data/roles/roles"
import { AppRoutes } from "src/router/AppRoutes"
import { paletteColor, primaryText } from "src/ui/colors"
import { ReactComponent as AdminIcon } from "src/ui/icons/admin-panel.svg"
import { ReactComponent as CardTravelIcon } from "src/ui/icons/CardTravel.svg"
import { ReactComponent as CorporateFareIcon } from "src/ui/icons/CorporateFare.svg"
import { ReactComponent as LoanIcon } from "src/ui/icons/credit-score.svg"
import { ReactComponent as DashboardIcon } from "src/ui/icons/dashboard.svg"
import { ReactComponent as Logo } from "src/ui/icons/logo.svg"
import { ReactComponent as InterestIcon } from "src/ui/icons/monetisation.svg"
import { ReactComponent as PersonIcon } from "src/ui/icons/person.svg"
import { ReactComponent as UsersIcon } from "src/ui/icons/users-group.svg"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { spacing } from "src/ui/spacing"
import {
  Heading5,
  TypographyBody2,
  TypographySubtitle1,
} from "src/ui/typography"

interface IAppRoute {
  name: string
  requiredRoles?: string[]
  path: string
  icon?: ReactNode
  items?: IAppRoute[]
}

const navRoutes: IAppRoute[] = [
  {
    name: "Dashboard",
    path: AppRoutes.Basic.dashboard,
    icon: <DashboardIcon />,
    requiredRoles: [UserRole.USER],
  },
  {
    name: "User profile",
    path: AppRoutes.ProfileRoutes.userProfile,
    icon: <PersonIcon />,
    requiredRoles: [UserRole.USER],
  },
  {
    name: "Investments",
    path: AppRoutes.InvestmentRoutes.investments,
    icon: <CardTravelIcon />,
    requiredRoles: [UserRole.USER],
  },
  {
    name: "Interest",
    path: AppRoutes.GroupInterestRoutes.groupInterest,
    icon: <InterestIcon />,
    requiredRoles: [UserRole.USER],
  },
  {
    name: "Loans",
    path: AppRoutes.LoansRoutes.loans,
    icon: <LoanIcon />,
    requiredRoles: [UserRole.USER],
  },
  {
    name: "Users",
    path: AppRoutes.UsersRoute,
    icon: <UsersIcon />,
    requiredRoles: [UserRole.USER],
  },
  {
    name: "Admin",
    path: AppRoutes.Basic.admin,
    icon: <AdminIcon />,
    requiredRoles: ADMINS,
  },
  {
    name: "Tenants",
    path: AppRoutes.Tenants,
    icon: <CorporateFareIcon />,
    requiredRoles: SUPER,
  },
]

export function SideNav({ navLinkClick = () => {}, ...props }) {
  const { auth } = useAuth()
  const user = auth?.user
  const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.S})`, {
    noSsr: true,
  })

  const fetchOrganisation = useFetchOrganisation({
    organisationId: user?.organisationId || "",
    options: { enabled: !!user?.organisationId },
  })

  const organisation = fetchOrganisation.data

  if (fetchOrganisation.isLoading) {
    return <LoadingScreen />
  }

  return (
    <div>
      <NavMain>
        <LogoBox>
          <Logo width="50px" height="50px" />
          <Heading5>{organisation?.name}</Heading5>
        </LogoBox>

        {navRoutes.map((item, index) => {
          const hasRequiredUserRoles = hasRequiredRoles({
            userRoles: user?.role || [],
            requiredRoles: item.requiredRoles || [],
          })
          return (
            <StyledLi
              key={index}
              hidden={!hasRequiredUserRoles}
              isMobileScreen={isMobileScreen}
            >
              <StyledNavlink to={item.path} onClick={navLinkClick}>
                <IconBox> {item?.icon}</IconBox>
                {item.name}
              </StyledNavlink>
            </StyledLi>
          )
        })}
      </NavMain>
    </div>
  )
}

const NavMain = styled.ul`
  display: grid;
  padding: 0;
  margin: 0;

  & a {
    opacity: 0.7;
    color: ${primaryText};
    text-decoration: none;
  }

  & a:hover {
    opacity: 1;
    background: ${paletteColor.primary.lighter};
  }

  & a.active {
    opacity: 1;
    background: ${paletteColor.primary.lighter};
    color: ${paletteColor.primary.main};
    border-right: 4px solid ${paletteColor.primary.main};
  }
`

const StyledNavlink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: ${spacing.S} ${spacing.M} ${spacing.S} ${spacing.L};
  transition: all 0.1s ease-in-out;
`

const StyledLi = styled.li<{ hidden: boolean; isMobileScreen: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "block")};

  ${(props) => (props.isMobileScreen ? TypographySubtitle1 : TypographyBody2)};
  font-weight: 500;
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${spacing.M};
`

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  align-items: center;
  margin: ${spacing.M};
`
