export const userKeys = {
  all() {
    return ["users"]
  },

  userList(filters?: { skip?: number; limit?: number; userIds?: string[] }) {
    return [
      ...this.all(),
      `skip=${filters?.skip ?? ""}, limit=${filters?.limit ?? ""} `,
    ]
  },

  user(userId: string) {
    return [...this.userList(), userId]
  },
} as const
