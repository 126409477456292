import { ReactNode } from "react"
import styled from "styled-components/macro"

import {
  Alert,
  ButtonBaseProps,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import { breakpoints } from "src/data/constants/breakpoints"
import { MButton } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"

export function MDialog({
  open,
  onClose,
  onConfirm,
  title,
  body,
  formId,
  confirmLabel = "Ok",
  cancelLabel = "Cancel",
  showCancel = true,
  fullWidth = true,
  responsive = true,
  errorText,
  confirmButtonProps,
  loading,
  children,
}: {
  open: boolean
  onClose?: () => void
  onConfirm?: () => void
  title: ReactNode
  body?: ReactNode
  formId?: string
  confirmLabel?: string
  cancelLabel?: string
  showCancel?: boolean
  fullWidth?: boolean
  responsive?: boolean
  errorText?: string
  confirmButtonProps?: ButtonBaseProps & ButtonProps
  loading?: boolean
  children?: ReactNode
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={!breakpoints.M && responsive}
      fullWidth={fullWidth}
    >
      <div>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <StyledTitle>{body ?? children}</StyledTitle>
        </DialogContent>
        {errorText && <AlertBox severity="error">{errorText}</AlertBox>}
        <StyledActions>
          <div>
            {showCancel && (
              <MButton variant="outlined" onClick={onClose}>
                {cancelLabel}
              </MButton>
            )}
          </div>

          {!!formId ? (
            <MButton
              type="submit"
              form={formId}
              onClick={onConfirm}
              loading={loading}
              disabled={loading}
              {...confirmButtonProps}
            >
              {confirmLabel}
            </MButton>
          ) : (
            <MButton
              onClick={onConfirm}
              loading={loading}
              disabled={loading}
              {...confirmButtonProps}
            >
              {confirmLabel}
            </MButton>
          )}
        </StyledActions>
      </div>
    </Dialog>
  )
}

const StyledTitle = styled.div`
  margin-top: ${spacing.XS};
`

const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.S};

  @media (min-width: ${breakpoints.XS}) {
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.06) 0px -2px 4px 0px;
    padding: ${spacing.S} ${spacing.L};
  }
`

const AlertBox = styled(Alert)`
  padding: ${spacing.L};
  // margin: ${spacing.S} 0;
`
