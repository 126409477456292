import { useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { IAuthUser } from "src/contexts/AuthProvider"
import { useFetchInvestmentSummary } from "src/data/investments/investmentQueries"
import { useFetchLoanSummary, usePostLoan } from "src/data/loans/loanQueries"
import { IOrganisation } from "src/data/organisation/organisationTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"
import { Heading5 } from "src/ui/typography"

export function LoanRequest({
  open,
  setOpen,
  auth,
  organisation,
}: {
  open: boolean
  setOpen: (status: boolean) => void
  auth: IAuthUser
  organisation: IOrganisation
}) {
  const [amount, setAmount] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  })
  const [comment, setComment] = useState("")
  const postLoan = usePostLoan()

  const fetchLoanSummary = useFetchLoanSummary({
    userId: auth?.user?.userId,
    loanStatus: "Active",
    reviewStatus: "Approved",
    options: { enabled: !!auth?.user?.userId },
  })

  const loanSummary = fetchLoanSummary.data

  const fetchInvestmentSummary = useFetchInvestmentSummary({
    userId: auth?.user?.userId,
    reviewStatus: "Approved",
    options: { enabled: !!auth?.user?.userId },
  })

  const investmentSummary = fetchInvestmentSummary.data

  const availableToMe =
    (investmentSummary?.totalInvestment ?? 0) -
    (loanSummary?.totalPrinciple ?? 0)

  function handlePostLoan() {
    return (
      auth?.user &&
      postLoan.mutate(
        {
          amount: amount.floatValue ?? 0,
          interestRate: organisation?.interestRate,
          comment,
        },
        {
          onSuccess: () => setOpen(false),
        }
      )
    )
  }

  return (
    <MDialog
      open={open && !!auth?.user?.userId}
      title="Request new loan"
      onClose={() => {
        setOpen(false)
        setAmount({
          floatValue: undefined,
          formattedValue: "",
          value: "",
        })
        postLoan.reset()
      }}
      confirmLabel="Make loan request"
      onConfirm={handlePostLoan}
      loading={postLoan.isPending}
      confirmButtonProps={{
        disabled:
          (amount.floatValue ?? 0) <= 0 ||
          !comment ||
          (amount.floatValue ?? 0) > availableToMe,
      }}
      errorText={
        postLoan.isError ? "There was a problem saving your instalment" : ""
      }
    >
      <Box>
        <NumericFormat
          label="Amount to borrow"
          value={amount.formattedValue}
          defaultValue="0"
          //onChange={(e) => setAmount(e.target.value)}
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setAmount(values)}
          required
          disabled={availableToMe <= 0}
        />
        <TextField
          fullWidth
          label="Reason"
          required
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        Available to you: {availableToMe.toLocaleString("en-GB")} UGX
        <InterestBox>
          <Heading5>Interest rate: {organisation?.interestRate} % p/a</Heading5>
          <div>
            * By making this loan request, you agree to take it at the indicated
            interest rate and to the loan terms and conditions of the
            organisation.
          </div>
        </InterestBox>
        {(availableToMe <= 0 || (amount.floatValue ?? 0) > availableToMe) && (
          <Alert severity="error">
            You can only borrow what is available to you.
          </Alert>
        )}
      </Box>
    </MDialog>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL};
  align-items: center;
`

const InterestBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`
