export enum UserRole {
  SUPER = "SUPER",
  USER = "USER",
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  INVESTMENT = "INVESTMENT",
  INTEREST = "INTEREST",
  LOAN = "LOAN",
  LIMITED = "LIMITED",
  ORGANISATION = "ORGANISATION",
}

export const ADMINS = [UserRole.ORGANISATION, UserRole.ADMIN]
export const SUPER = ["SUPER"]

export function hasRequiredRoles({
  userRoles,
  requiredRoles,
}: {
  userRoles: string[]
  requiredRoles: string[]
}) {
  if (requiredRoles.length <= 0) {
    // If no roles are required, then show , i.e it is an open link
    return true
  }
  const hasRequiredRoles =
    requiredRoles?.filter((role) => userRoles?.includes(role)).length >= 1
  return hasRequiredRoles
}
