import { ChangeEvent, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { AppRoutes } from "src/router/AppRoutes"
import { MButton } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"
import { Heading2 } from "src/ui/typography"

export function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(false)

  async function handleSubmit(event: ChangeEvent<{}>) {
    event.preventDefault()
    try {
      setMessage(true)
    } catch (e) {
      setError(true)
      throw new Error("Failed to reset password")
    }
  }

  return (
    <Box>
      <Title>Password Reset</Title>
      {error && (
        <StyledAlert severity="error">
          Failed to reset your password
        </StyledAlert>
      )}
      {message && (
        <StyledAlert severity="success">
          Check your inbox for further instructions
        </StyledAlert>
      )}
      <FormBox onSubmit={handleSubmit}>
        <TextField
          disabled={message}
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <MButton type="submit" disabled={!email || message}>
          Reset password
        </MButton>
        <StyledLink to={AppRoutes.Basic.login}>Log in</StyledLink>
      </FormBox>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  justify-content: center;
  min-height: 100vh;
  margin: auto;
`

const Title = styled.div`
  margin-bottom: ${spacing.XL};
  text-align: center;
  ${Heading2};
`

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
  padding-bottom: ${spacing.L};
`

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacing.L};
`
const StyledLink = styled(Link)`
  text-align: center;
`
