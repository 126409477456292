import { TReviewStatus } from "src/data/constants/globalTypes"
import { TLoanStatus } from "src/data/loans/loanTypes"
import { cleanObjectConvertToMap } from "src/utils/utils"

export const loanKeys = {
  all() {
    return ["loans"] as const
  },

  loans(filters?: {
    skip?: number
    limit?: number
    userId?: string
    reviewStatus?: TReviewStatus
    loanStatus?: TLoanStatus
    startDate?: string
    endDate?: string
  }) {
    if (!filters) {
      return this.all()
    }

    return [...this.all(), cleanObjectConvertToMap(filters)] as const
  },

  loan(userId: string) {
    return [...this.all(), userId]
  },

  loanSummary(filters?: {
    userId?: string
    loanStatus?: TLoanStatus
    reviewStatus?: TReviewStatus
  }) {
    if (!filters) {
      return this.all()
    }

    return [...this.all(), cleanObjectConvertToMap(filters)]
  },

  personal({
    userId,
    skip,
    limit,
  }: {
    userId: string
    skip?: number
    limit?: number
  }) {
    return [...this.all(), userId]
  },

  loanDates(tenantId: string) {
    return [...this.all(), tenantId]
  },
} as const

export const instalmentKeys = {
  all({ skip, limit }: { skip?: number; limit?: number }) {
    return ["instalments", `skip=${skip}`, `limit=${limit}`]
  },

  instalment(userId: string) {
    return [...this.all({}), userId]
  },

  instalmentDates(tenantId: string) {
    return [...this.all({}), tenantId]
  },

  interestDue(loanId: string) {
    return [...this.all({}), loanId]
  },
} as const
