import { useMutation } from "@tanstack/react-query"

import { baseHttpClient } from "src/api/httpClient"
import { IAuthUser } from "src/contexts/AuthProvider"
import { IPostLogin, IPostResetPassword } from "src/data/auth/authTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

export const authAPI = "/auth"

export function usePostLogin() {
  async function postLogin({ email, password }: IPostLogin) {
    const response = await baseHttpClient.post<IAuthUser>(`${authAPI}/login`, {
      email,
      password,
    })
    return response.data
  }

  return useMutation({ mutationFn: postLogin })
}

export function useResetPassword() {
  const axiosPrivate = useAxiosHttp()
  async function resetPassword({
    oldPassword,
    newPassword,
  }: IPostResetPassword) {
    const response = await axiosPrivate.post(
      `${authAPI}/reset`,
      JSON.stringify({ oldPassword, newPassword })
    )
    return response.data
  }

  return useMutation({ mutationFn: resetPassword })
}
