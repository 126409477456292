import { useState } from "react"
import styled from "styled-components/macro"

import { AddGroupInterest } from "src/components/GroupInterest/AddGroupInterest"
import { MChip } from "src/components/MChip/MChip"
import { MPagination } from "src/components/Pagination/MPagination"
import { MTable } from "src/components/Table/MTable"
import { useAuth } from "src/contexts/AuthProvider"
import { MAX_LIMIT } from "src/data/constants/constants"
import { useFetchMonthlyInterest } from "src/data/groupInterest/groupInterestQueries"
import { UserRole } from "src/data/roles/roles"
import { useFetchUsers } from "src/data/users/userQueries"
import { MButton } from "src/ui/Button/MButton"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { PageTitle } from "src/ui/PageTitle/PageTitle"
import { Caption } from "src/ui/typography"
import { getDateOnly, statusToColorProfile } from "src/utils/utils"

export function GroupInterest() {
  const { auth } = useAuth()
  const limit = MAX_LIMIT
  const [offset, setOffset] = useState(0)
  const [open, setOpen] = useState(false)
  const fetchMonthlyInterest = useFetchMonthlyInterest({ skip: offset, limit })

  const monthlyInterest = fetchMonthlyInterest.data?.interest || []
  const userIds = monthlyInterest.map((interest) => interest.addedBy) || []

  const fetchUsers = useFetchUsers({
    userIds,
    options: { enabled: userIds.length > 0 },
  })
  const users = fetchUsers.data?.users || []

  const headerData = [
    "Interest month",
    "Amount (UGX)",
    "Added by",
    "Status",
    "Date approved",
    "Comment",
  ]
  const header = headerData.map((item) => <div>{item}</div>)

  const rows = monthlyInterest.map((interest) => {
    const reqDate = getDateOnly(interest.createdAt)
    const updatedDate = getDateOnly(interest.updatedAt)

    const user = users.find((user) => user.userId === interest.addedBy)

    return (
      <>
        <div>
          {interest.month} {interest.year}
          <Caption>{reqDate}</Caption>
        </div>
        <div>{interest.amount.toLocaleString("en-GB")}</div>
        <div>{user?.name || "Unknown user"}</div>
        <div>
          <MChip
            label={interest.reviewStatus}
            color={statusToColorProfile(interest.reviewStatus)}
          />
        </div>
        <div>{updatedDate}</div>
        <div>{interest.comment}</div>
      </>
    )
  })

  if (fetchMonthlyInterest.isPending || fetchUsers.isLoading) {
    return <LoadingScreen />
  }

  return (
    <Box>
      <PageTitle pageLabel="Group Interest"></PageTitle>
      <MTable
        title="Status"
        header={header}
        rows={rows}
        templateColumns={`repeat( 6, minmax(min-content, 1fr))`}
        action={
          auth?.user?.role.includes(UserRole.INTEREST) && (
            <MButton size="small" onClick={() => setOpen(true)}>
              Add group interest
            </MButton>
          )
        }
        pagination={
          <MPagination
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchMonthlyInterest.data?.totalCount || 0}
          />
        }
      />

      {!!auth && <AddGroupInterest open={open} setOpen={setOpen} auth={auth} />}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
`
