import { createGlobalStyle } from "styled-components/macro"

import { paletteColor } from "src/ui/colors"

export const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
    font-family: Roboto, Oxygen, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale;
    color: #193446;
    font-weight: ${400};
    line-height: 1.5rem;
    background-color: ${paletteColor.secondary.lighter};
    box-sizing: border-box;
  }
  
  input, button, textarea, select  {
    font: inherit;
  }
  
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root, #__next {
    isolation: isolate;
  }
  `
