import { createTheme } from "@mui/material"

export const theme = createTheme({
  palette: {
    divider: "#EAEAEA",

    primary: {
      dark: "#096dd9",
      main: "#1890ff",
      light: "#69c0ff",
    },

    secondary: {
      dark: "#262626",
      main: "#8c8c8c",
      light: "#d9d9d9",
    },

    success: {
      dark: "#237804",
      main: "#52C41A",
      light: "#95de64",
    },

    warning: {
      dark: "#B78103",
      main: "#FFC107",
      light: "#FFE16A",
    },
    error: {
      dark: "#B72136",
      main: "#FF4842",
      light: "#FFA48D",
    },
  },

  typography: {
    fontFamily:
      '"Public Sans","Roboto","Oxygen","Segoe UI","Helvetica Neue","Arial", "sans-serif"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    button: {
      fontWeight: 400,
      textTransform: "none",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },
  },
})
