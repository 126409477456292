import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"

import { Admin } from "src/components/Admin/Admin"
import { AppLayout } from "src/components/AppLayout/AppLayout"
import { PersistLogin } from "src/components/Auth/PersistLogin"
import { Dashboard } from "src/components/Dashboard/Dashboard"
import { ForgotPassword } from "src/components/ForgotPassword/ForgotPassword"
import { GroupInterest } from "src/components/GroupInterest/GroupInterest"
import { Investments } from "src/components/Investments/Investments"
import { Loans } from "src/components/Loans/Loans"
import { Login } from "src/components/Login/Login"
import { ResetPassword } from "src/components/ResetPassword/ResetPassword"
import { CreateTenant } from "src/components/Tenants/CreateTenant"
import { TenantDetails } from "src/components/Tenants/TenantDetails"
import { Tenants } from "src/components/Tenants/Tenants"
import { UserProfile } from "src/components/UserProfile/UserProfile"
import { Users } from "src/components/Users/Users"
import { useAuth } from "src/contexts/AuthProvider"
import { AppRoutes } from "src/router/AppRoutes"
import { NotFound } from "src/router/NotFound"

function RequireAuth() {
  const { auth } = useAuth()
  const location = useLocation()

  return auth ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate
      to={AppRoutes.Login.path()}
      state={{ path: location.pathname }}
      replace
    />
  )
}

export function AppRouter() {
  const location = useLocation()

  return (
    <Routes>
      {/* Public routes */}
      <Route path={AppRoutes.Basic.login} element={<Login />} />
      <Route
        path={AppRoutes.Basic.forgotPassword}
        element={<ForgotPassword />}
      />
      <Route path={AppRoutes.Login.paramPath} element={<Login />} />

      {/* Protected routes */}
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Dashboard />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path={AppRoutes.Basic.login}
            element={
              <Navigate
                to={AppRoutes.Basic.dashboard}
                replace
                state={{ path: location.pathname }}
              />
            }
          />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path={AppRoutes.Basic.dashboard} element={<Dashboard />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path={AppRoutes.Tenants} element={<Tenants />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path={AppRoutes.CreateTenant} element={<CreateTenant />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path={AppRoutes.Tenant.paramPath}
            element={<TenantDetails />}
          />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path={AppRoutes.UsersRoute} element={<Users />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path={AppRoutes.ProfileRoutes.userProfile}
            element={<UserProfile />}
          />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path={AppRoutes.ProfileRoutes.resetPassword}
            element={<ResetPassword />}
          />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path={AppRoutes.LoansRoutes.loans} element={<Loans />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path={AppRoutes.GroupInterestRoutes.groupInterest}
            element={<GroupInterest />}
          />
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            path={AppRoutes.InvestmentRoutes.investments}
            element={<Investments />}
          />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path={AppRoutes.Basic.admin} element={<Admin />} />
        </Route>
      </Route>

      {/* Catch all route. Should lead to a not found page */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
