import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"

import { TReviewStatus } from "src/data/constants/globalTypes"
import { investmentKeys } from "src/data/investments/investmentQueriesCache"
import {
  IFetchInvestments,
  IFetchInvestmentSummary,
  IInvestment,
  IInvestmentsByDate,
  IPostInvestmentRequest,
} from "src/data/investments/investmentTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"
import { cleanQueryKeyDateString } from "src/utils/utils"

const investmentAPI = "/investment"

export function usePostInvestment() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function postInvestment({
    userId,
    amount,
    month,
    year,
    comment,
  }: IPostInvestmentRequest) {
    const response = await axiosPrivate.post<IInvestment>(`${investmentAPI}`, {
      userId,
      amount,
      month,
      year,
      comment,
    })
    return response.data
  }

  return useMutation({
    mutationFn: postInvestment,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: investmentKeys.all() }),
  })
}

export function useFetchInvestments(filter: {
  skip?: number
  limit?: number
  userId?: string
  reviewStatus?: TReviewStatus
  startDate?: string
  endDate?: string
  options?: Partial<UseQueryOptions<IFetchInvestments>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInvestments() {
    const response = await axiosPrivate.get<IFetchInvestments>(
      `${investmentAPI}`,
      {
        params: {
          ...filter,
        },
      }
    )
    return response.data
  }

  return useQuery({
    queryKey: investmentKeys.investments({
      ...filter,
      startDate: cleanQueryKeyDateString(filter.startDate),
      endDate: cleanQueryKeyDateString(filter.endDate),
    }),
    queryFn: fetchInvestments,
    ...filter.options,
  })
}

export function useFetchInvestmentsByDate({
  tenantId,
  startDate,
  endDate,
}: {
  tenantId: string
  startDate?: string
  endDate?: string
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInvestmentsByDate() {
    const response = await axiosPrivate.get<IInvestmentsByDate[]>(
      `${investmentAPI}/tenant/${tenantId}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  }

  return useQuery({
    queryKey: investmentKeys.investmentDate(tenantId),
    queryFn: fetchInvestmentsByDate,
    // enabled: !!tenantId,
  })
}

export function useFetchInvestmentSummary({
  userId,
  reviewStatus,
  options,
}: {
  userId?: string
  reviewStatus?: TReviewStatus
  options?: Partial<UseQueryOptions<IFetchInvestmentSummary>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchInvestmentSummary() {
    const response = await axiosPrivate.get<IFetchInvestmentSummary>(
      `${investmentAPI}/total/summary`,
      { params: { userId, reviewStatus } }
    )
    return response.data
  }

  return useQuery({
    queryKey: investmentKeys.investmentSummary({ userId, reviewStatus }),
    queryFn: fetchInvestmentSummary,
    ...options,
  })
}
