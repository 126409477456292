export const grey = {
  300: "#DEE2E6",
  light: "#707070",
  mild: "#EAEAEA",
  background: "#F4F4F4",
  back: "#F0F2F5",
  default: "#7B809A",
}

export const primaryAction = "#219AF6" //"#307CBF"
export const primaryText = "#010101"
export const sideNavBlue = "#F4F7F8"

export const colors = {
  primary: "#1A73E8",
  secondary: "#E91E63",
  default: "#7B809A",
  success: "#4CAF50",
  warning: "#FB8C00",
  error: "#F44335",
  light: "#F0F2F5",
  dark: "#344767",
  orange: "#A0A69A",
  green: "#67B39D",
  purple: "#5048E4",
  oceanBlue: "#0998FF",
  lightBluex: "#A0D7FE",
  divider: "#EAEAEA",
}

export const paletteColor = {
  primary: {
    darker: "#003a8c",
    dark: "#096dd9",
    main: "#1890ff",
    light: "#69c0ff",
    lighter: "#e6f7ff",
  },

  secondary: {
    darker: "#000000",
    dark: "#262626",
    main: "#8c8c8c",
    light: "#d9d9d9",
    lighter: "#fafafa",
  },
  success: {
    darker: "#092b00",
    dark: "#237804",
    main: "#52c41a",
    light: "#95de64",
    lighter: "#f6ffed",
  },

  warning: {
    darker: "613400",
    dark: "#ad6800",
    main: "#faad14",
    light: "#ffd666",
    lighter: "#fffbe6",
  },
  error: {
    darker: "5c0011",
    dark: "#a8071a",
    main: "#f5222d",
    light: "#ff7875",
    lighter: "#fff1f0",
  },
  divider: "#EAEAEA",
  //divider: "#f0f0f0",
}
