import { useState } from "react"
import styled from "styled-components/macro"

import { IconButton } from "@mui/material"
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material"

import { LoanCard } from "src/components/Loans/LoanCard"
import { LoanRequest } from "src/components/Loans/LoanRequest"
import { ServiceLoan } from "src/components/Loans/ServiceLoan"
import { MPagination } from "src/components/Pagination/MPagination"
import { MTable } from "src/components/Table/MTable"
import { useAuth } from "src/contexts/AuthProvider"
import { MAX_LIMIT } from "src/data/constants/constants"
import { useFetchLoans } from "src/data/loans/loanQueries"
import { ILoan } from "src/data/loans/loanTypes"
import { useFetchOrganisation } from "src/data/organisation/organisationQueries"
import { UserRole } from "src/data/roles/roles"
import { MButton } from "src/ui/Button/MButton"
import { ReactComponent as AddOutlinedIcon } from "src/ui/icons/Add.svg"
import { ReactComponent as MoreHorizIcon } from "src/ui/icons/MoreHoriz.svg"
import { spacing } from "src/ui/spacing"

export function PersonalLoans() {
  const { auth } = useAuth()
  const limit = MAX_LIMIT
  const [open, setOpen] = useState(false)
  const [loan, setLoan] = useState<ILoan | null>(null)
  const [selectedLoan, setSelectedLoan] = useState<ILoan | null>(null)

  const [offset, setOffset] = useState(0)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const fetchLoans = useFetchLoans({
    userId: auth?.user?.userId ?? "",
    limit: 10,
    skip: offset,
    loanStatus: "Active",
    options: { enabled: !!auth?.user?.userId },
  })
  const loans = fetchLoans.data?.loans || []

  const fetchOrganisation = useFetchOrganisation({
    organisationId: auth?.user?.organisationId || "",
  })
  const organisation = fetchOrganisation.data

  const popoverId = !!anchorEl ? "user-setting" : undefined

  const rows = loans.map((loan) => (
    <ServiceBox>
      <LoanCard loan={loan} />
      <ButtonIconBox>
        <IconButton
          aria-label="loan-request"
          aria-describedby={popoverId}
          onClick={(e) => {
            setSelectedLoan(loan)
            setAnchorEl(e.currentTarget)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </ButtonIconBox>
    </ServiceBox>
  ))

  return (
    <div>
      <MTable
        title="My loans (UGX)"
        rows={rows}
        templateColumns="1fr"
        action={
          auth?.user?.role.includes(UserRole.LOAN) && (
            <MButton size="small" onClick={() => setOpen(true)}>
              Request new loan
            </MButton>
          )
        }
        pagination={
          <MPagination
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchLoans.data?.totalCount ?? 0}
          />
        }
      />

      {loan && <ServiceLoan key={loan.loanId} loan={loan} setLoan={setLoan} />}

      {!!auth && !!organisation && (
        <LoanRequest
          key={`${open}`}
          open={open}
          setOpen={setOpen}
          auth={auth}
          organisation={organisation}
        />
      )}

      <Popover
        id={popoverId}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListItemButton
          onClick={() => {
            setAnchorEl(null)
            setLoan(selectedLoan)
          }}
        >
          <ListItemIcon>
            <AddOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Add instalment" />
        </ListItemButton>
      </Popover>
    </div>
  )
}

const ServiceBox = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${spacing.L};
  align-items: center;
`

const ButtonIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
