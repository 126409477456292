import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"

import { payoutKeys } from "src/data/annualInterestPayout/annualInterestPayoutKeys"
import { IAnnualInterestPayout } from "src/data/annualInterestPayout/annualInterestPayoutTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

const payoutApi = "/payout"

export function useFetchAnnualInterestPayout({
  startDate,
  endDate,
  options,
}: {
  startDate: string
  endDate: string
  options?: Partial<UseQueryOptions<IAnnualInterestPayout>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchAnnualInterestPayout() {
    const response = await axiosPrivate.get<IAnnualInterestPayout>(
      `${payoutApi}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  }

  return useQuery({
    queryKey: payoutKeys.all({ startDate, endDate }),
    queryFn: fetchAnnualInterestPayout,
    ...options,
  })
}

export function usePostAnnualInterestPayout() {
  const axiosPrivate = useAxiosHttp()

  async function postAnnualInterestPayout({
    amount,
    year,
    startDate,
    endDate,
  }: {
    amount: number
    year: number
    startDate: string
    endDate: string
  }) {
    const response = await axiosPrivate.post(`${payoutApi}`, {
      amount,
      year,
      startDate,
      endDate,
    })
    return response.data
  }

  return useMutation({
    mutationFn: postAnnualInterestPayout,
  })
}
