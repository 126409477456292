import styled from "styled-components/macro"

import { CircularProgress } from "@mui/material"

import { colors } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { TypographyBody2, TypographySubtitle2 } from "src/ui/typography"

function getFr(value?: number) {
  if (!value) return ""
  return "1fr ".repeat(value).trim()
}

export function GridTable({
  header,
  rows,
  templateColumns = "1fr",
  onRowClick,
  loading,
}: {
  header?: JSX.Element[]
  rows: JSX.Element[]
  templateColumns?: string
  onRowClick?: () => void
  loading?: boolean
}) {
  const gridTemplateColumns = templateColumns || getFr(header?.length)

  if (loading) {
    return <StyledCircularProgress />
  }

  return (
    <GridSection templateColumns={gridTemplateColumns}>
      {!!header?.length && (
        <StyledHeader>
          {header?.map((columnHeader, index) => (
            <div key={index}>{columnHeader}</div>
          ))}
        </StyledHeader>
      )}
      {rows.map((row, index) => (
        <StyledRow key={index}>{row}</StyledRow>
      ))}
    </GridSection>
  )
}

const GridSection = styled.section<{ templateColumns: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.templateColumns};
`

const StyledHeader = styled.header`
  display: contents;
  ${TypographySubtitle2};

  & > * {
    border-bottom: 1px solid ${colors.light};
    padding: ${spacing.XS} ${spacing.M};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const StyledRow = styled.div`
  display: contents;
  ${TypographyBody2};
  :hover > * {
    background: ${colors.default}18;
  }

  > * {
    display: flex;
    // border-bottom: 1px solid ${colors.light};
    padding: ${spacing.M};
    flex-direction: column;
    justify-content: center;
  }
`

const StyledCircularProgress = styled(CircularProgress)``
