export enum Status {
  ACTIVE = "Active",
  APPROVED = "Approved",
  PAID = "Paid",
  PENDING = "Pending",
  REJECTED = "Rejected",
  CANCELLED = "Cancelled",
}

export enum Month {
  JANUARY = "January",
  FEBRUARY = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
  JULY = "July",
  AUGUST = "August",
  SEPTEMBER = "September",
  OCTOBER = "October",
  NOVEMBER = "November",
  DECEMBER = "December",
}
