import { TReviewStatus } from "src/data/constants/globalTypes"
import { cleanObjectConvertToMap } from "src/utils/utils"

export const interestKeys = {
  all() {
    return ["interest"] as const
  },

  interest(filters?: {
    skip?: number
    limit?: number
    startDate?: string
    endDate?: string
    reviewStatus?: TReviewStatus
    userId?: string
    isInterestPaid?: boolean
  }) {
    if (!filters) {
      return this.all()
    }

    return [...this.all(), cleanObjectConvertToMap(filters)] as const
  },

  interestDate(tenantId: string) {
    return [...this.all(), tenantId]
  },

  combinedInterestByDate(filters: { startDate: string; endDate: string }) {
    return [...this.all(), filters]
  },

  interestSummary(filters?: { userId?: string; reviewStatus?: TReviewStatus }) {
    return [
      ...this.all(),
      `userId=${filters?.userId}`,
      `reviewStatus=${filters?.reviewStatus}`,
    ]
  },
} as const
