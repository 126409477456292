import { ChangeEvent, useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import styled from "styled-components/macro"

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { format, getYear } from "date-fns"

import { IAuthUser } from "src/contexts/AuthProvider"
import { Month } from "src/data/general/generalTypes"
import { usePostInvestment } from "src/data/investments/investmentQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

export function AddInvestment({
  open,
  setOpen,
  auth,
}: {
  open: boolean
  setOpen: (status: boolean) => void
  auth: IAuthUser
}) {
  const postInvestment = usePostInvestment()
  const [amount, setAmount] = useState<NumberFormatValues>({
    floatValue: undefined,
    formattedValue: "",
    value: "",
  })
  const [year, setYear] = useState(getYear(Date.now()))
  const [comment, setComment] = useState("")
  const [month, setMonth] = useState<Month>(format(Date.now(), "MMMM") as Month)

  function handleMonthChange(event: SelectChangeEvent<Month>) {
    setMonth(event.target.value as Month)
  }

  function handleChangeYear(
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const value = e.target.value

    if (isNaN(parseInt(value))) {
      return
    }

    setYear(parseInt(value))
  }

  function handlePostInvestment() {
    return (
      auth?.user &&
      postInvestment.mutate(
        {
          userId: auth.user.userId,
          amount: amount.floatValue ?? 0,
          month,
          year,
          comment,
        },
        {
          onSuccess: () => {
            setOpen(false)
            setAmount({
              floatValue: undefined,
              formattedValue: "",
              value: "",
            })
            postInvestment.reset()
          },
        }
      )
    )
  }

  return (
    <MDialog
      open={open}
      title="Add your investment"
      onClose={() => {
        setOpen(false)
        setAmount({
          floatValue: undefined,
          formattedValue: "",
          value: "",
        })
        postInvestment.reset()
      }}
      confirmLabel="Add investment"
      onConfirm={handlePostInvestment}
      loading={postInvestment.isPending}
      confirmButtonProps={{
        disabled: parseInt(amount.value) <= 0 || isNaN(parseInt(amount.value)),
      }}
      errorText={
        postInvestment.isError
          ? "There was a problem saving your investment"
          : ""
      }
    >
      <StyledMBox>
        <NumericFormat
          label="Investment"
          value={amount.formattedValue}
          defaultValue="0"
          //onChange={(e) => setAmount(e.target.value)}
          decimalScale={4}
          thousandSeparator=","
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros={false}
          onValueChange={(values) => setAmount(values)}
          required
        />
        <InvestmentPeriodBox>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month}
              label="Month"
              onChange={handleMonthChange}
            >
              {Object.values(Month).map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Year"
            required
            value={year}
            onChange={handleChangeYear}
            //disabled={true}
          />
        </InvestmentPeriodBox>

        <TextField
          fullWidth
          label="Comment"
          value={comment}
          onChange={(event) => setComment(event.currentTarget.value)}
        />
      </StyledMBox>
    </MDialog>
  )
}

const StyledMBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  align-items: center;
`

const InvestmentPeriodBox = styled.div`
  display: flex;
  gap: ${spacing.XL};
  align-items: center;
`
