const Tenants = "/admin/tenants"
const CreateTenant = `${Tenants}/new-tenant`

const Tenant = {
  path: (tenantCode: string) => `${Tenants}/${tenantCode}`,
  get paramPath() {
    return this.path(":tenantCode")
  },
}

export const TenantRoutes = {
  Tenants,
  CreateTenant,
  Tenant,
} as const
