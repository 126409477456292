import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"

import { notificationKeys } from "src/data/notifications/notificationQueriesCache"
import {
  IFetchNotifications,
  IPostReviewResponse,
} from "src/data/notifications/notificationTypes"
import { useAxiosHttp } from "src/hooks/useAxiosPrivate"

const notificationAPI = "/notification"

export function usePatchNotificationReview() {
  const axiosPrivate = useAxiosHttp()
  const queryClient = useQueryClient()

  async function patchNotificationReview(reviewResponse: IPostReviewResponse) {
    const response = await axiosPrivate.patch(
      `${notificationAPI}/${reviewResponse.notificationId}`,
      {
        ...reviewResponse,
      }
    )

    return response.data
  }

  return useMutation({
    mutationFn: patchNotificationReview,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: notificationKeys.all() }),
  })
}

export function useFetchNotifications(filters?: {
  restrictToUser: boolean
  skip?: number
  limit?: number
  senderId?: string
  isOpen?: boolean
  options?: Partial<UseQueryOptions<IFetchNotifications>>
}) {
  const axiosPrivate = useAxiosHttp()

  async function fetchNotifications() {
    const response = await axiosPrivate.get<IFetchNotifications>(
      `${notificationAPI}`,
      {
        params: filters,
      }
    )

    return response.data
  }
  return useQuery({
    queryKey: notificationKeys.notifications({ ...filters }),
    queryFn: fetchNotifications,
    ...filters?.options,
  })
}
