import { ChangeEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import { Alert, TextField } from "@mui/material"

import { useResetPassword } from "src/data/auth/authQueries"
import { MButton } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"
import { Heading4 } from "src/ui/typography"

export function ResetPassword() {
  const navigate = useNavigate()
  const resetPassword = useResetPassword()
  const [newPassword, setNewPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [error, setError] = useState(false)

  async function handleSubmit(event: ChangeEvent<{}>) {
    event.preventDefault()

    resetPassword.mutate(
      { oldPassword, newPassword },
      {
        onSuccess: () => {
          setError(false)
          return navigate(-1)
        },
        onError: () => setError(true),
      }
    )
  }

  return (
    <Box>
      <Grid>
        <Heading4>Reset your password</Heading4>
        <div>
          <FormBox onSubmit={handleSubmit}>
            Old password:
            <TextField
              label="Old password"
              value={oldPassword}
              type="password"
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            New password:
            <TextField
              label="New password"
              value={newPassword}
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {error && (
              <Alert severity="error">
                There was a problem resetting your password
              </Alert>
            )}
            <ActionBox>
              <MButton
                variant="outlined"
                onClick={() => {
                  setError(false)
                  navigate(-1)
                }}
              >
                Cancel
              </MButton>
              <MButton type="submit">Reset newPassword</MButton>
            </ActionBox>
          </FormBox>
        </div>
      </Grid>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.M};
  padding-bottom: ${spacing.L};
`

const ActionBox = styled.div`
  display: flex;
  gap: ${spacing.XL};
  justify-content: space-between;
  min-width: 400px;
`
